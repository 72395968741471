import { lazy } from 'react';
import lazyLoader from 'src/lazyLoader';

// Entrypoints. Have to be in this file due to how chunks are formed
export const SignUp = lazy(() => import('src/pages/signup/SignUp'));
export const Layout = lazy(() => import('src/layout/Layout.jsx'));
export const Public = lazy(() => import('src/public/Public.jsx'));


// Settings page components
export const InvoicingForm = lazy(() => lazyLoader(import('src/pages/settings/Invoicing/InvoicingForm')));
export const OffersForm = lazy(() => lazyLoader(import('src/pages/settings/Offers/OffersForm')));
export const NotificationsForm = lazy(() => lazyLoader(import('src/pages/settings/Notifications/NotificationsForm')));
export const IntegrationView = lazy(() => lazyLoader(import('src/pages/settings/Integration/IntegrationView')));
export const GeneralForm = lazy(() => lazyLoader(import('src/pages/settings/General/GeneralForm')));
export const EmployeesForm = lazy(() => lazyLoader(import('src/pages/settings/Employees/EmployeesForm')));
export const MyselfSettings = lazy(() => lazyLoader(import('src/pages/settings/myself/MyselfSettings')));
export const ProjectsForm = lazy(() => lazyLoader(import('src/pages/settings/Projects/ProjectSetupSettingView/ProjectsForm')));
export const QualitySettings = lazy(() => lazyLoader(import('src/pages/settings/quality/QualitySettings')));
export const TextsView = lazy(() => lazyLoader(import('src/pages/settings/Texts/TextsView')));
export const ExportTemplatesView = lazy(() => lazyLoader(import('src/pages/settings/ExportTemplates/ExportTemplatesView')));
export const HourlyRates = lazy(() => lazyLoader(import('src/pages/settings/priceList/HourlyRates/HourlyRates')));
export const ProductPriceList = lazy(() => lazyLoader(import('src/pages/settings/priceList/ProductPriceList/ProductPriceList')));
export const NewPriceListHourlyRates = lazy(() => lazyLoader(import('src/pages/settings/priceList/actionBtn/NewPriceListHourlyRates')));
export const NewPriceListProduct = lazy(() => lazyLoader(import('src/pages/settings/priceList/actionBtn/NewPriceListProduct')));
export const ServiceMessagesView = lazy(() => lazyLoader(import('src/pages/settings/ServiceMessages/ServiceMessagesView')));
export const CustomFieldsSetup = lazy(() => lazyLoader(import('src/pages/settings/customFieldsSetup/CustomFieldsSetup')));
export const GeneralTagsSetupView = lazy(() => lazyLoader(import('src/pages/settings/tagsSetup/GeneralTagsSetupView')));
export const DocumentTemplatesView = lazy(() => lazyLoader(import('src/pages/settings/DocumentTemplates/DocumentTemplatesView')));
export const NotificationTemplatesView = lazy(() => lazyLoader(import('src/pages/settings/NotificationTemplates/NotificationTemplatesView')));
export const UserRoutePreferencesView = lazy(() => lazyLoader(import('src/pages/settings/RoutePreferences/UserRoutePreferencesView')));
export const CompanyRoutePreferencesView = lazy(() => lazyLoader(import('src/pages/settings/RoutePreferences/CompanyRoutePreferencesView')));
export const ScheduledTasksView = lazy(() => lazyLoader(import('src/pages/settings/ScheduledTasks/ScheduledTasksView')));
export const CalendarImportView = lazy(() => lazyLoader(import('src/pages/settings/myself/components/CalendarImportView')));
export const ApiAccessView = lazy(() => lazyLoader(import('src/pages/company/ApiAccessView')));

// My pages components
export const OverviewMainPage = lazy(() => lazyLoader(import('src/pages/myself/OverviewMainPage/OverviewMainPage')));
export const NotificationsOverview = lazy(() => lazyLoader(import('src/pages/myself/Notifications/NotificationsOverview')));
export const AbsenceReportView = lazy(() => lazyLoader(import('src/pages/myself/AbsenceReport/AbsenceReportView')));

// Project page components
export const ProjectFilesPage = lazy(() => lazyLoader(import('src/pages/projects/FilesView/ProjectFilesPage')));
export const AvailabilityView = lazy(() => lazyLoader(import('src/pages/projects/AvailabilityView/AvailabilityView')));
export const InfolandProducts = lazy(() => lazyLoader(import('src/pages/projects/Ambita/InfolandProducts')));
export const ActivitiesView = lazy(() => lazyLoader(import('src/pages/projects/ActivitiesView/ActivitiesView')));
export const QualitySystemCompetency = lazy(() => lazyLoader(import('src/pages/projects/competency/QualitySystemCompetencyView')));
export const ReviewsView = lazy(() => lazyLoader(import('src/pages/projects/reviews/ReviewsView')));
export const NonconformityView = lazy(() => lazyLoader(import('src/pages/projects/NonconformityView/NonconformityView')));
export const ProjectsTimelineView = lazy(() => lazyLoader(import('src/pages/projects/timeline/projectsTimelineView/ProjectsTimelineView')));
export const ProjectsMileStonesView = lazy(() => lazyLoader(import('src/pages/projects/timeline/projectsMileStonesView/ProjectsMileStonesView')));
export const AccrualReportView = lazy(() => lazyLoader(import('src/pages/projects/accrualReportView/AccrualReportView')));
export const ProjectDocumentView = lazy(() => lazyLoader(import('src/pages/projects/ProjectDocument/ProjectDocumentView')));
export const PrintableTimeRecord = lazy(() => lazyLoader(import('src/pages/projects/timeRecord/printableTimeRecord/PrintableTimeRecord')));
export const ProjectInvoiceSettingView = lazy(() => lazyLoader(import('src/pages/settings/Projects/ProjectInvoiceSettingView')));
export const ProjectBudgetView = lazy(() => lazyLoader(import('src/pages/projects/BudgetView/BudgetView')));
export const ProjectMainPage = lazy(() => lazyLoader(import('src/pages/projects/MainPage/ProjectMainPage')));
export const CustomerMainPage = lazy(() => lazyLoader(import('src/pages/customers/MainPage/CustomerMainPage')));
export const ActivitiesReportView = lazy(() => lazyLoader(import('src/pages/projects/ActivitiesReport/ActivitiesReportView')));
export const ProjectsDashboard = lazy(() => lazyLoader(import('src/pages/projects/dashboard/ProjectsDashboard')));
export const ProjectManagerDashboard = lazy(() => lazyLoader(import('src/pages/projects/pm-dashboard/ProjectManagerDashboard')));

// Quality page components
export const RiskAssessmentView = lazy(() => lazyLoader(import('src/pages/qualitySystem/riskAssessmentView/RiskAssessmentView')));
export const RiskManagementView = lazy(() => lazyLoader(import('src/pages/qualitySystem/riskManagementView/RiskManagementView')));
export const RiskReportView = lazy(() => lazyLoader(import('src/pages/qualitySystem/risk/RiskReport/RiskReportView')));
export const RoutinesView = lazy(() => lazyLoader(import('src/pages/qualitySystem/routines/RoutinesView')));
export const ProjectReviewsView = lazy(() => lazyLoader(import('src/pages/qualitySystem/projectReviewsView/ProjectReviewsView')));
export const NonconformitiesTemplatesView = lazy(() => lazyLoader(import('src/pages/qualitySystem/nonconformitiesTemplates/NonconformitiesTemplatesView')));
export const QualitySystemView = lazy(() => lazyLoader(import('src/pages/qualitySystem/checklists/QualitySystemView')));
export const ChecklistForm = lazy(() => lazyLoader(import('src/components/qualitySystem/checklists/ChecklistForm/ChecklistForm')));
export const TasksOverviewView = lazy(() => lazyLoader(import('src/pages/qualitySystem/tasksOverviewView/TasksOverviewView')));
export const TaskMainPage = lazy(() => lazyLoader(import('src/pages/tasks/MainPage/TaskMainPage')));
export const ChecklistReportView = lazy(() => lazyLoader(import('src/pages/qualitySystem/checklists/ChecklistReport/ChecklistReportView')));

// Checklist page components
export const ChecklistProjectView = lazy(() => lazyLoader(import('src/pages/checklist/ChecklistProjectView/ChecklistProjectView')));
export const ChecklistDetailsView = lazy(() => lazyLoader(import('src/pages/checklist/ChecklistDetailsView/ChecklistDetailsView')));


// Tasks
export const TaskEditorInjection = lazy(() => lazyLoader(import('src/pages/tasks/TaskEditorInjection')));

// Overviews page components
export const ProjectsOverviewView = lazy(() => lazyLoader(import('src/pages/overviews/ProjectsOverviewView')));
export const UsersOverviewView = lazy(() => lazyLoader(import('src/pages/overviews/UsersOverviewView')));
export const CustomersOverviewView = lazy(() => lazyLoader(import('src/pages/overviews/CustomersOverviewView')));
export const ContactsOverviewPage = lazy(() => lazyLoader(import('src/pages/customers/ContactsOverviewPage/ContactsOverviewPage')));
export const CustomerInvoiceSettingView = lazy(() => lazyLoader(import('src/pages/settings/customer/CustomerInvoiceSettingView/CustomerInvoiceSettingView')));

// Contact pages
export const ContactDetailsPage = lazy(() => lazyLoader(import('src/pages/customers/ContactsOverviewPage/ContactDetailsPage')));
export const ContactsReportView = lazy(() => lazyLoader(import('src/pages/customers/ContactsReportPage/ContactsReportView')));

// Company page components
export const HolidaysView = lazy(() => lazyLoader(import('src/pages/company/HolidaysView/HolidaysView')));
export const CompanyHierarchy = lazy(() => lazyLoader(import('src/pages/company/CompanyHierarchy')));
export const CompanyBudgetView = lazy(() => lazyLoader(import('src/pages/company/CompanyBudgetView')));
export const BudgetView = lazy(() => lazyLoader(import('src/pages/company/budget/BudgetView')));
export const SignaturesView = lazy(() => lazyLoader(import('src/pages/company/SignaturesView')));

// Other page components
export const UsersPerformanceView = lazy(() => lazyLoader(import('src/pages/company/usersPerformance/UsersPerformanceView')));
export const HoursReportView = lazy(() => lazyLoader(import('src/pages/hours/HoursReportView')));
export const NotificationsView = lazy(() => lazyLoader(import('src/pages/users/preferences/NotificationsView/NotificationsView')));
export const EmailDistributionView = lazy(() => lazyLoader(import('src/pages/customers/EmailDistribution/EmailDistributionView')));

// Education fund page components
export const ContinuesEducationAccrualSetup = lazy(() => lazyLoader(import('src/pages/settings/EmployeesDevelopment/AccrualSetup/ContinuesEducationAccrualSetup')));
export const MyEducationFundsOverview = lazy(() => lazyLoader(import('src/pages/company/employeesEducation/employeesEducationOverview/MyEducationFunds')));
export const EducationFundsOverview = lazy(() => lazyLoader(import('src/pages/company/employeesEducation/employeesEducationOverview/EducationFunds')));
export const EducationOverview = lazy(() => lazyLoader(import('src/pages/company/educationOverview/EducationOverview')));

// Invoices
export const InvoiceLinesReportView = lazy(() => lazyLoader(import('src/pages/invoices/invoiceLines/InvoiceLinesReportView')));

// Resources page component
export const AbsenceOverview = lazy(() => lazyLoader(import('src/pages/resources/AbsenceOverview/AbsenceOverview')));
export const TimeBalance = lazy(() => lazyLoader(import('src/pages/resources/TimeBalance/TimeBalance')));

// Settings page component
export const AccessControlView = lazy(() => lazyLoader(import('src/pages/settings/accessControl/AccessControlView')));
export const AccessControlContainerUpdate = lazy(() => lazyLoader(import('src/pages/settings/accessControl/AccessControlContainerUpdate')));
export const AccessControlContainerNew = lazy(() => lazyLoader(import('src/pages/settings/accessControl/AccessControlContainerNew')));

// Modals
export const ActivitiesSettingsModal = lazy(() => lazyLoader(import('src/pages/projects/ActivitiesView/activitiesSettingsModal/ActivitiesSettingsModal')));
