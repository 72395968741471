import { cApi, MakeCb } from 'src/api';
import { useMakeQuery } from 'src/hooks/useMakeQuery';

/**
 *
 * @param {number|string} projectNumber
 * @returns {object|undefined} project object
 */
const useProject = (projectNumber) => {
  const { data } = useMakeQuery(
    MakeCb.get.include.all(cApi.projects,
      ['base', 'details', 'department', 'projectOwners', 'address', 'fileStorageLocation'],
      { query: { projectNumber } }
    ),
    { enabled: !!projectNumber, refetchOnMount: false, keepPreviousData: true }
  );

  return data?.content?.[0];
};

export default useProject;
