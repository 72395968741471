import React, { ReactNode } from 'react';
import { useSingleton } from 'src/hooks';
import { QueryClient, QueryClientProvider } from 'react-query';


const defaultClient = () => new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
      refetchInterval: false,
      refetchIntervalInBackground: false,
      staleTime: 5 * 60 * 1000 // 5 minutes
    }
  }
});

const DefaultQueryClientProvider = ({ children }: {children?: ReactNode | undefined}) => {

  const client = useSingleton(defaultClient);

  return (
    <QueryClientProvider client={client}>{children}</QueryClientProvider>
  );
};


export default DefaultQueryClientProvider;
