import { useMemo } from 'react';
import { cApi, MakeCb, SIZE_ALL } from 'src/api';
import { useMakeQuery } from 'src/hooks/useMakeQuery';
import { t } from 'src/i18n/i18next';

const useCompanyPartnershipCodes = (currentCompanyId, allowAccessor, useCompanyPartnerships) => {
  const { data: { content: companyPartnerships } = {}, isFetching } = useMakeQuery(MakeCb.get.all(cApi.companyPartnerships, {
    query: {
      direction: 'source',
      sort: 'desc',
      size: SIZE_ALL
    }
  }), { enabled: useCompanyPartnerships === 'true' });

  const {sourceCompanyCodes = [], companyMap = {} } = useMemo(() => {

    if (companyPartnerships) {
      const filtered = companyPartnerships?.filter(element => element[allowAccessor]);
      if (filtered.length === 0) {
        return {};
      }
      const companyMap = filtered.reduce((r, e) => {
        r[e.destinationCompanyId] = e.destinationCompanyCode;
        return r;
      }, {});
      const companyIds = Object.keys(companyMap);
      const sourceCompanyCodes = [
        {
          value: '',
          label: t('global.words.None'),
          title: t('global.words.None')
        },
        {
          value: [...companyIds, currentCompanyId].join(','),
          label: t('global.boolean.all'),
          title: t('global.boolean.all')
        },
        ...filtered.filter(element => element.destinationCompanyId !== currentCompanyId)
          .map(company => {
            return {
              value: company.destinationCompanyId.toString(),
              label: company.destinationCompanyName,
              title: company.destinationCompanyName
            };
          })
      ];
      return { sourceCompanyCodes, companyMap };
    }
    return {};
  }, [companyPartnerships]);

  return {sourceCompanyCodes, companyMap, isFetching};
};

export default useCompanyPartnershipCodes;
