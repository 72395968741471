import { QueryKeyStrict } from '../api/types';
import { LoadingMessage, MessagesConfig } from '../types/useStatusMessage';
import useStatusMessage from './useStatusMessage';

export const useInjectStatusMessagesInCallbacks = (key: QueryKeyStrict, config: Omit<MessagesConfig, 'method' | 'key'>): LoadingMessage => {
  const { loadingMessage, successMessage, errorMessage } = useStatusMessage({ key, silent: config.silent, messages: config.messages });

  const _onSuccess = config.onSuccess;
  config.onSuccess = (...args) => {
    successMessage();
    _onSuccess?.(...args);
  };

  const _onError = config.onError;
  config.onError = (...args) => {
    errorMessage(null, args[0]);
    _onError?.(...args);
  };

  return loadingMessage;
};
