import { useEffect, useCallback } from 'react';

const useContextClick = (ref, callback) => {
  const eventKey = 'contextmenu';

  const onClick = useCallback((event) => {
    event.preventDefault();
    callback?.(event);
  }, [callback]);

  useEffect(() => {
    if (ref.current) {
      ref.current.addEventListener(eventKey, onClick);
    }
    return () => {
      if (ref.current) {
        ref.current.removeEventListener(eventKey, onClick);
      }
    };
  }, [ref, onClick]);
};

export default useContextClick;
