
const withSlash = (path = '') => path[path.length - 1] === '/' ? path : path + '/';

const join = (...parts) => {
  if (parts.every(p => typeof p === 'number' || typeof p === 'string')) {

    const cleanParts = parts.map(p => ('' + p).replace(/^\/|\/$/g, '')).filter(p => !!p);
    return '/' + cleanParts.join('/');
  }

  console.error(`Path parts list is invalid:`, parts);
};

const applyParams = (path = '', params = {}) => {
  for (const paramName of Object.keys(params)) {
    if (params[paramName] === undefined && !path.includes(':' + paramName + '?')) {
      console.error(`Param '${paramName}' is undefined. Make sure to put it to 'enabled' flag of the react-query config`);
    }
    path = path.replace(new RegExp(':' + paramName + '\\??'), params[paramName] || '');
  }
  path = path[path.length - 1] === '/' ? path.substring(0, path.length - 1) : path;
  return path;
};

const applyParamsToMatch = (match, params = {}) => applyParams(match.path, { ...match.params, ...params });


const Path = {
  withSlash,
  join,
  applyParams,
  applyParamsToMatch
};

export default Path;

