const lazyLoader = (lazyComponent) => {
  return new Promise((resolve, reject) => {
    lazyComponent.then(resolve).catch(error => {
      if (error.name === 'ChunkLoadError' && error.message.includes('missing')) {
        setTimeout(() => document.location.reload(), 1);
        reject(`Wasn't able to load lazy chunk. Most likely the version is obsolete. Reloading`, error);
      }
      reject(error);
    });
  });
};

export default lazyLoader;
