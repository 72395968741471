import { isEqual } from 'lodash';


const isEqualSorted = (a1 = [], a2 = []) => isEqual(a1.sort(), a2.sort());
const fromComaString = (val = '') => Array.isArray(val) ? val : (val === '' ? [] : val.split(','));
const toComaString = (array = []) => array.sort().join(',');
const toComaNotSortedString = (array = []) => array.join(',');
const joinJSX = (list, separator) => list?.length ? list.reduce((prev, curr) => [].concat(prev, separator, curr)) : null;

const ensureArray = data => Array.isArray(data) ? data : [data];

const prefillArray = (length, getValue) => {
  return [...new Array(length)].map(getValue);
};

const Arrays = {
  isEqualSorted,
  fromComaString,
  toComaString,
  toComaNotSortedString,
  joinJSX,
  ensureArray,
  prefillArray
};

export default Arrays;
