import md5 from 'blueimp-md5';
import Identity from 'src/api/Identity';

function getGravatarUrl(email = 'test@example.com', size = 32) {
  const md5Email = md5(email);
  return `//www.gravatar.com/avatar/${md5Email}?s=${size}&d=mm`; // mm = mystery man
}

function getCloudFileUrl(cloudFileId, companyCode = Identity.companyCode, size, crop) {
  if (!cloudFileId) {
    return null;
  }
  const sizePart = size ? `/${crop ? 'crop' : 'realMax'}${size}x${size}` : '';
  return `${CONFIG.apiUrl}/companies/${companyCode}/files/${cloudFileId}/content${sizePart}`;
}

function contentUrlAsAttachment (cloudFileId, companyCode) {
  return contentUrl(cloudFileId, companyCode, true);
}

function contentUrl (cloudFileId, companyCode = Identity.companyCode, asAttachment = false) {
  return `${CONFIG.apiUrl}/companies/${companyCode}/files/${cloudFileId}/contentRedirect?asAttachment=${asAttachment}`;
}

// options: id, size, email, version
function iconUrl({ id, companyCode = Identity.companyCode, size = 120, crop = true, email = null }) {
  if (id) {
    return getCloudFileUrl(id, companyCode, size, crop);
  } else {
    return email ? getGravatarUrl(email, size) : null;
  }
}

function companyIconUrl(company) {
  const id = company.iconLogoId || company.iconId;
  return iconUrl({ id, companyCode: company.code });
}



const CloudFile = {
  contentUrlAsAttachment,
  cloudContentUrl: contentUrl,
  companyIconUrl,
  iconUrl,
  fileUrl: iconUrl,
  logoUrl: iconUrl
};

export default CloudFile;
