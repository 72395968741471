import { createContext, useEffect, useMemo, useRef, useState } from 'react';
import { useInfiniteQuery, useMutation, useQuery, UseQueryResult } from 'react-query';
import { pageable } from 'src/api';
import useInvalidateQueries from 'src/hooks/useInvalidateQueries';
import usePartialUpdates from 'src/hooks/usePartialUpdates';
import { useInitialInfiniteQueryData, useInitialQueryData } from 'src/hooks/useResortExistingData';
import useSingleton from 'src/hooks/useSingleton';
import useStatusMessage from 'src/hooks/useStatusMessage';
import { BaseEntity, Content, Data, Page, PagedData, QueryKey, ReportData, ReportEntity } from 'src/api/types';
import { Method } from 'src/api/localEnums';
import {
  BatchResult,
  InfiniteQueryConfig,
  InfiniteQueryOptions,
  InfiniteQueryResult,
  MutationConfig,
  MutationOptions,
  QueryConfig, QueryCountConfig,
  QueryOptions,
  UseMutationResultWithOptionsParams
} from '../types/useMakeQuery';
import { useInjectStatusMessagesInCallbacks } from './useInjectStatusMessagesInCallbacks';

const OneMethods = [
  Method.PostOne,
  Method.PutOne,
  Method.PatchOne,
  Method.DeleteOne
];

/**
 * {@link useQuery} wrapper to handle caching and messaging. You can use native method if your data is not cacheable
 * @param {object} obj
 * @param {array} obj.key - key representation
 * @param {function} obj.request - fetch method to pass to {@link useQuery}
 * @param {object} [config] - config object passed to the {@link useQuery} method, with some parameters listed below used by the wrapper
 * @param {object} [config.messages={@link defaultQueryMessages}] - { loading, success, error } messages to override default ones
 * @param {boolean} [config.keepPreviousPagination] - alternative to keepPreviousData to keep pagination
 * @param {boolean} [config.silent=true] - do not render messages
 * @param {boolean} [config.enabled=true] - inherited from {@link useQuery}
 * @return {object} - {@link useQuery} result
 */
function useMakeQuery<E extends number = number>({ method, key, request }: QueryOptions<E>, config?: QueryCountConfig): UseQueryResult<E>;
function useMakeQuery<E extends BaseEntity = BaseEntity>({ method, key, request }: QueryOptions<E>, config?: QueryConfig<E>): UseQueryResult<E>;
function useMakeQuery<E extends BaseEntity = BaseEntity>({ method, key, request }: QueryOptions<PagedData<E>>, config?: QueryConfig<E>): UseQueryResult<PagedData<E>>;
function useMakeQuery<E extends BaseEntity = BaseEntity, S extends ReportEntity = null, G extends Record<string, unknown> = Record<string, unknown>>(
  { method, key, request }: QueryOptions<ReportData<E, S, G>>, config?: QueryConfig<E>):
  UseQueryResult<ReportData<E, S, G>>;
function useMakeQuery<E extends BaseEntity = BaseEntity>({ method, key, request }: QueryOptions<Data<E>>, config: QueryConfig<E> = {}): UseQueryResult<Data<E>> {
  if (method) {
    throw new Error('You should use useMakeMutation instead');
  }
  config = Object.assign({ silent: true }, config);
  if (config.keepPreviousPagination === undefined && !config.keepPreviousData) {
    config.keepPreviousPagination = true;
  }

  const [page, setPage] = useState<Page>();

  const initialData = useInitialQueryData<E>(key);
  if (!config.keepPreviousData && initialData && !config.initialData) {
    config.initialData = initialData;
    config.staleTime = 120000; // 2 minutes for resorted data
  }

  const loadingMessage = useInjectStatusMessagesInCallbacks(key, config);

  let outerStack = '';
  try {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    ({}).debug(); // supposed to fail, saving stacktrace
  } catch (ex) {
    outerStack = ex.stack.replace(/^.*(?=\n)/, '');
  }

  const query = useQuery(key, () => {
    try {
      return request(undefined);
    } catch (e) {
      e.message += outerStack + '\nat INITIAL STACK BELOW';
      throw new Error(e);
    }
  }, config);

  if (query.isError) {
    const error = query.error;
    if ([401, 403].includes(error.code)) {
      throw new Error(JSON.stringify(query.error));
    }
  }

  if (query.isFetching) {
    loadingMessage();
  }

  const data = query.data;

  if (data?.page && config.keepPreviousPagination && data.page !== page) {
    setPage(data.page as Page);
  }

  if (config.keepPreviousPagination) {
    return { ...query, data: data || (page ? { page } : undefined) } as UseQueryResult<Data<E>>;
  }

  return query;
}

/**
 * {@link useInfiniteQuery} wrapper
 * @param {object} obj
 * @param {QueryKeyStrict} obj.key - key representation
 * @param {function} obj.request - fetch method to pass to {@link useInfiniteQuery}
 * @param {object} [config] - config object passed to the {@link useInfiniteQuery} method, with some parameters listed below used by the wrapper
 * @param {object} [config.messages={@link defaultQueryMessages}] - { loading, success, error } messages to override default ones
 * @param {object} [config.enabled=true] - inherited from {@link useInfiniteQuery}
 * @param {bool} [config.silent=true] - do not render messages
 * @return {object} - {@link useInfiniteQuery} result
 */
const useMakeInfiniteQuery = <E extends BaseEntity = BaseEntity>(
  { method, key, request, initialQuery }: InfiniteQueryOptions<E>, config: InfiniteQueryConfig<E> = {}
): InfiniteQueryResult<E> => {
  if (method) {
    throw new Error('You should use useMakeMutation instead');
  }

  config = Object.assign({
    silent: true,
    keepPreviousData: false,
    getNextPageParam: ({ page }: { page: Page }) => {
      const nextNumber = page.number + 1;
      return nextNumber < page.totalPages ? nextNumber : undefined;
    }
  }, config);

  const loadingMessage = useInjectStatusMessagesInCallbacks(key, config);

  const queryFetch = ({ pageParam }: { pageParam?: number }) => {
    const query = { ...initialQuery, ...pageable(pageParam) };
    return request(query);
  };

  const initialData = useInitialInfiniteQueryData<E>(key);
  if (!config.keepPreviousData && initialData && !config.initialData) {
    config.initialData = initialData;
    config.staleTime = 120000; // 2 minutes for resorted data
  }

  const query = useInfiniteQuery(key, queryFetch, config);

  const pages = query.data?.pages;

  const queryMethods = {
    hasNextPage: query.hasNextPage,
    fetchNextPage: query.fetchNextPage
  };
  const fetchMoreRef = useRef(queryMethods);

  useEffect(() => {
    fetchMoreRef.current = queryMethods;
  }, [query]);

  // returning singleton to prevent re-renders
  query.fetchNextPage = useSingleton(() => () => {
    // on call fetchMore() by default react-query will be "re-rendered" even if no new data available
    // so calling only if we have more data to load
    if (fetchMoreRef.current.hasNextPage) {
      fetchMoreRef.current.fetchNextPage();
    }
  });

  if (query.isFetching) {
    loadingMessage();
  }

  const combinedData = useMemo((): PagedData<E> => {
    if (!pages?.[0]) {
      return {} as PagedData<E>;
    }
    const lastPage = pages[pages.length - 1];
    const content: Content<E> = pages.reduce((memo: Content<E>, page: PagedData<E>) => {
      memo.push(...page.content);
      return memo;
    }, []) || [];
    return ({
      content,
      page: {
        ...lastPage.page,
        size: content.length
      },
      metadata: pages[0]?.metadata
    });
  }, [pages]);

  return {
    ...query,
    data: combinedData
  };
};

/**
 * {@link useMutation} wrapper to handle caching and messaging. You can use native method if query data is not cacheable and always `silent: true`
 * @param {object} obj
 * @param {string} obj.method - post/get/put/delete
 * @param {array} obj.key - key representation
 * @param {function} obj.request - fetch method to pass to {@link useMutation}
 * @param {object} [config] - config object passed to the {@link useMutation} method, with some parameters listed below used by the wrapper
 * @param {object} [config.messages={@link getDefaultMutationMessages}] - { loading, success, error } messages to override default ones
 * @param {boolean} [config.silent=false] - do not render messages
 * @param {boolean} [config.invalidateCache=true] - flag to invalidate caches on mutation by first part of the `key` param
  * @param {boolean|array} [config.invalidateHardCache=false] - flag to invalidate caches and refetch active requests. Can be set to key to be invalidated.
 * Note: `true` by default for post.one requests
 * @param {boolean} [config.updateQueries=true] - flag to perform partial updates to same key queries + related keys for list queries
 * @param {boolean} [config.removeOldCache=false] - flag to invalidate caches and remove current cache (useful to workaround initialValue issues)
 * @param {boolean} [config.invalidateRelated=true] - flag to invalidate caches for related queries (might not make sense when updating, e.g. sortOrder)
 * @param {boolean} [config.enabled=true] - inherited from {@link useMutation}
 * @param {string} [config.mutationKey] - inherited from {@link useMutation}
 * @param {function} [config.onError] - inherited from {@link useMutation}
 * @param {function} [config.onMutate] - inherited from {@link useMutation}
 * @param {function} [config.onSettled] - inherited from {@link useMutation}
 * @param {function} [config.onSuccess] - inherited from {@link useMutation}
 * @param {array} [config.addTo] - query key for adding created entity {@link usePartialUpdates}
 * @param {string} [config.addPosition] - [start|end] {@link usePartialUpdates}
 * @param {array} [config.updateIn] - query key for updating entities {@link usePartialUpdates}
 * @param {array} [config.deleteFrom] - query key for deleting entity from {@link usePartialUpdates}
 * @param {boolean} [config.useErrorBoundary] - inherited from {@link useMutation}
 * @return {object} - {@link useMutation} result
 */
function useMakeMutation<E = BatchResult>({ method, key, request }: MutationOptions<E>, config?: MutationConfig): UseMutationResultWithOptionsParams<E>;
function useMakeMutation<E extends BaseEntity = BaseEntity>({ method, key, request }: MutationOptions<E>, config: MutationConfig = {}): UseMutationResultWithOptionsParams<E> {
  if (!method) {
    throw new Error('You should use useMakeQuery instead');
  }

  config = Object.assign({
    invalidateCache: true,
    updateQueries: true,
    invalidateRelated: true
  }, config);
  if (method === Method.PostOne && config.invalidateHardCache === undefined) {
    config.invalidateHardCache = true;
  }
  const invalidateKey = ['boolean', 'undefined'].includes(typeof config.invalidateHardCache) ? key : config.invalidateHardCache;
  const invalidateQueries = useInvalidateQueries(
    invalidateKey as QueryKey, !!config.invalidateHardCache, !!config.removeOldCache, invalidateKey !== key, !!config.invalidateRelated
  );

  const { loadingMessage, successMessage, errorMessage } = useStatusMessage({ key, silent: config.silent, method, messages: config.messages });
  const doPartialUpdate = usePartialUpdates(config);

  const _onSuccess = config.onSuccess;
  config.onSuccess = (data, variables, context) => {
    if (config.updateQueries && (OneMethods.includes(method) || method === Method.PostBatch)) {
      setTimeout(() => doPartialUpdate(key, method, data, variables), 1);
    }
    if (method === Method.PostBatch && (data as BatchResult).created.length > 0 && !config.addTo) {
      config.invalidateHardCache = true;
    }
    if (config.invalidateCache || config.invalidateHardCache) {
      let id;
      if (OneMethods.includes(method)) {
        id = ((data as BaseEntity)?.id || Number(key.find(kPart => typeof kPart === 'string' && kPart.startsWith('id:'))?.substring(3)) || variables)?.toString();
      }
      invalidateQueries(id, !!config.invalidateHardCache);
    }
    successMessage();
    return _onSuccess?.(data, variables, context);
  };

  const _onError = config.onError;
  config.onError = (...args) => {
    errorMessage(null, args[0]);
    return _onError?.(...args);
  };

  const mutation = useMutation<E>(request, config);

  const _mutate = mutation.mutate;
  mutation.mutate = (...args) => {
    loadingMessage();
    return _mutate(...args);
  };
  const _mutateAsync = mutation.mutateAsync;
  mutation.mutateAsync = (...args) => {
    loadingMessage();
    return _mutateAsync(...args);
  };

  return mutation;
}

export {
  useMakeQuery,
  useMakeInfiniteQuery,
  useMakeMutation
};

export const MutationContext = createContext(null);
