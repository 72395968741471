import { useMemo } from 'react';
import { Enums } from 'src/api';
import usePreferences from 'src/hooks/usePreference/usePreferences';

/**
 * Getting dateFormatList based on prefs
 */
const dateFormattingMap = new Map([
  [Enums.DateFormatting.dmy_dot, ['DD.MM.YYYY', 'DD.M.YYYY', 'D.MM.YYYY', 'D.M.YYYY', 'DD.MM.YY', 'DD.M.YY', 'D.M.YY',
    'DD.MM', 'DD.M', 'D.M', 'DDMMYYYY', 'DDMMYY', 'DDMM', 'DD', 'D']],
  [Enums.DateFormatting.ymd_dot, ['YYYY.MM.DD', 'YYYY.M.DD', 'YYYY.MM.D', 'YYYY.M.D', 'YY.MM.DD', 'YY.M.DD', 'YY.M.D',
    'MM.DD', 'M.DD', 'M.D', 'YYYYMMDD', 'YYMMDD', 'MMDD', 'DD', 'D']],
  [Enums.DateFormatting.dmy_slash, ['DD/MM/YYYY', 'DD/M/YYYY', 'D/MM/YYYY', 'D/M/YYYY', 'DD/MM/YY', 'DD/M/YY', 'D/M/YY',
    'DD/MM', 'DD/M', 'D/M', 'DDMMYYYY', 'DDMMYY', 'DDMM', 'DD', 'D']],
  [Enums.DateFormatting.mdy_slash, ['MM/DD/YYYY', 'M/DD/YYYY', 'MM/D/YYYY', 'M/D/YYYY', 'MM/DD/YY', 'M/DD/YY', 'M/D/YY',
    'MM/DD', 'M/DD', 'M/D', 'MMDDYYYY', 'MMDDYY', 'MMDD', 'DD', 'D']],
  [Enums.DateFormatting.ss_dash, ['YYYY-MM-DD', 'YYYY-M-DD', 'YYYY-MM-D', 'YYYY-M-D', 'YY-MM-DD', 'YY-M-DD', 'YY-M-D',
    'MM-DD', 'M-DD', 'M-D', 'YYYYMMDD', 'YYMMDD', 'MMDD', 'DD', 'D']],
  [Enums.DateFormatting.mdy_dash, ['MM-DD-YYYY', 'M-DD-YYYY', 'MM-D-YYYY', 'M-D-YYYY', 'MM-DD-YY', 'M-DD-YY', 'M-D-YY',
    'MM-DD', 'M-DD', 'M-D', 'MMDDYYYY', 'MMDDYY', 'MMDD', 'DD', 'D']]
]);

export const useDateFormatList = () => {
  const preferences = usePreferences(Enums.Scope.company);

  return useMemo(() => {
    const dateFormatting = preferences?.['app.general.dateFormatting'];
    return dateFormattingMap.get(dateFormatting);
  }, [preferences]);
};

export default useDateFormatList;
