import i18next from 'i18next';
import React, { useState } from 'react';
import useOnMount from '../hooks/useOnMount';
import { supportedLanguages } from './languages';
import PropTypes from 'prop-types';

const t = i18next.t.bind(i18next);
const exists = i18next.exists.bind(i18next);

const mapJpaSystemExceptionErrorCode = (errorObj) => {
  const matchAllQuotes = errorObj?.message?.split('"');
  const key = matchAllQuotes?.find(item => exists(`global.serverException.constraints.${item}`)) || '';
  return t('constraints.' + `${key}`);
};


export const formatBackendErrorMessage = (errorJson, fallbackMsg) => {
  let errorMessage;
  const key = `global.serverException.${errorJson.errorCode}`;
  if (errorJson.exception === 'UnauthorizedException') {
    errorMessage = errorJson.message;
  } else if (errorJson.exception === 'JpaSystemException' && !errorJson.errorCode) {
    errorMessage = mapJpaSystemExceptionErrorCode(errorJson);
  } else if (exists(key)) {
    errorMessage = t(key, errorJson.arguments);
  } else if (errorJson.errorCode?.message) {
    errorMessage = errorJson.errorCode.message;
  } else if (fallbackMsg) {
    errorMessage = fallbackMsg;
  } else {
    errorMessage = 'Error';
  }
  return errorMessage;
};

// for backbone
try {
  window.t = (key, ...args) => {
    if (typeof args[0] === 'object') {
      return t(key, ...args);
    } else {
      return t(key, args);
    }
  };
  window.translateExists = exists;
// eslint-disable-next-line no-empty
} catch (e) {}

export const changeLanguage = async (language, isPublic = false) => {
  if (!language) {
    return console.error('No language has been provided for change');
  }
  if (i18next.hasResourceBundle(language, 'translation')) {
    i18next.changeLanguage(language);
  } else {
    const supportedLanguage = supportedLanguages[language];
    if (!supportedLanguage) {
      return console.error(`Language "${language}" is not supported`);
    }
    const resource = await import(`src/i18n/locales/${isPublic ? 'public' : 'client'}_${supportedLanguage}.json`);
    i18next.addResourceBundle(language,  'translation', resource);
    i18next.changeLanguage(language);
  }
};

/**
 * Hook to trigger re-render on language change
 * @returns {string} - language
 */
export const useLanguage = () => {

  const [state, setState] = useState(i18next.language);

  useOnMount(() => {
    i18next.on('languageChanged', function (language) {
      setState(language);
    });
  });

  return state;
};

export const InterpolateToJSX = ({ textKey, params, context }) => {
  const preparedArray = t(textKey, { interpolation: { prefix: '$${', suffix: '}$$' }, context }) // disabling interpolation
    .replace(/}(.?)/gm, '}%%$1')
    .replace(/(.?)\{/gm, '$1%%{')
    .split('%%');

  return <>{preparedArray.map(s => s.startsWith('{') ? params?.[s.substring(1, s.length - 1)] || s : s)}</>;
};

InterpolateToJSX.propTypes = {
  context: PropTypes.string,
  textKey: PropTypes.string.isRequired,
  params: PropTypes.object
};

/**
 *
 * @returns {Object.<string, string>} {countryCode: name} where name is i18n'd
 */
export const useCountries = () => {
  return tbglobal.countries; // from backbone global for now
};

export { i18next, t, exists };
