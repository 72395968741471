import { useEffect, useRef } from 'react';

function usePreviousWithDefault(value, initialValue) {
  const ref = useRef(initialValue);
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export default usePreviousWithDefault;
