import { cApi, MakeCb } from 'src/api';
import { useMakeQuery } from 'src/hooks/useMakeQuery';

/**
 *
 * @param {number|string} customerNumber
 * @returns {object|undefined} customer object
 */
const useCustomer = (customerNumber) => {
  const { data } = useMakeQuery(
    MakeCb.get.include.all(cApi.customers,
      ['base', 'fileStorageLocation', 'isSubcontractor', 'isCustomer'],
      { query: { customerNumber } }
    ),
    { enabled: !!customerNumber, refetchOnMount: false, keepPreviousData: true }
  );

  return data?.content?.[0];
};

export default useCustomer;
