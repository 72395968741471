import { Grid } from 'antd';
import { useIsomorphicLayoutEffect } from 'react-use';
import { Breakpoint } from 'antd/es';
import { UseMeasureRef } from 'react-use/lib/useMeasure';
import { useEffect, useMemo, useState } from 'react';
import { debounce } from 'lodash';
import createGlobalState from './createGlobalState';

const { useBreakpoint } = Grid;

declare type ResponsiveInput<T> = { xs?: T; sm?: T; md?: T; lg?: T; xl?: T; xxl?: T };

export enum BreakpointEnum { xs, sm, md, lg, xl, xxl }

const useIsMobileGlobalState = createGlobalState(false);

export const useIsMobile = () => {
  const [isMobile] = useIsMobileGlobalState();
  return isMobile;
};

// this method does rerender only once when breakpoint (sm < 786px <= md) is passed
export function useSetupIsMobile(): boolean {
  const [isMobile, setIsMobile] = useIsMobileGlobalState();

  const observer = useMemo(() => new ResizeObserver(debounce((entries) => {
      if (entries[0]) {
        const { width } = entries[0].contentRect;
        setIsMobile(width < 786);
      }
    })),
    []
  );

  useIsomorphicLayoutEffect(() => {
    observer.observe(document.querySelector('#app'));
    return () => {
      observer.disconnect();
    };
  }, [observer]);

  return isMobile;
}

function useResponsiveByBreakpoints<T>({ xs, sm, md, lg, xl, xxl }: ResponsiveInput<T>, breakpoints: Partial<Record<Breakpoint, boolean>>): T {
  return (
    breakpoints.xxl && xxl !== undefined ? xxl :
      breakpoints.xl && xl !== undefined ? xl :
        breakpoints.lg && lg !== undefined ? lg :
          breakpoints.md && md !== undefined ? md :
            breakpoints.sm && sm !== undefined ? sm : xs
  );
}

export const useContentRef = createGlobalState<Element>();

export function useBreakpointByCustomRef<E extends Element>(element: E): BreakpointEnum {
  const [breakpoint, setBreakpoint] = useState<BreakpointEnum>(BreakpointEnum.xxl);

  const observer = useMemo(() => new ResizeObserver(debounce((entries) => {
      if (entries[0]) {
        const { width } = entries[0].contentRect;
        let newBreakpoint = BreakpointEnum.xxl;
        if (width < 1600) {
          newBreakpoint = BreakpointEnum.xl;
          if (width < 1200) {
            newBreakpoint = BreakpointEnum.lg;
            if (width < 992) {
              newBreakpoint = BreakpointEnum.md;
              if (width < 786) {
                newBreakpoint = BreakpointEnum.sm;
                if (width < 576) {
                  newBreakpoint = BreakpointEnum.xs;
                }
              }
            }
          }
        }

        if (newBreakpoint !== breakpoint) {
          setBreakpoint(newBreakpoint);
        }
      }
    })),
    [breakpoint]
  );

  useIsomorphicLayoutEffect(() => {
    if (!element) {
      return;
    }
    observer.observe(element);
    return () => {
      observer.disconnect();
    };
  }, [element, observer]);

  return breakpoint;
}

export function useBreakpointRef<E extends Element>(): [UseMeasureRef, BreakpointEnum] {
  const [element, ref] = useState<E | null>(null);

  const breakpoint = useBreakpointByCustomRef(element);

  return [ref as UseMeasureRef, breakpoint];
}

export function useResponsiveByWindow<T>(responsiveInput: ResponsiveInput<T>): T {
  return useResponsiveByBreakpoints(responsiveInput, useBreakpoint());
}

const useGlobalBreakpointStateByContent = createGlobalState(BreakpointEnum.xxl);

export const useSetupBreakpointByContent = <E extends Element>(element: E) => {
  const [, setContentBreakpoint] = useGlobalBreakpointStateByContent();
  const currentContentBreakpoint = useBreakpointByCustomRef(element);

  useEffect(() => setContentBreakpoint(currentContentBreakpoint), [currentContentBreakpoint]);
};

function useResponsive<T>(responsiveInput: ResponsiveInput<T>): T;
function useResponsive<T>(responsiveInput: ResponsiveInput<T>, breakpoint: BreakpointEnum): T;
function useResponsive<T>(responsiveInput: ResponsiveInput<T>, breakpoint?: BreakpointEnum): T {
  const [contentBreakpoint] = useGlobalBreakpointStateByContent();
  let breakpoints = { xs: false, sm: true, md: true, lg: true, xl: true, xxl: true };

  switch (breakpoint || contentBreakpoint) {
    case BreakpointEnum.xl:
      breakpoints = { xs: false, sm: true, md: true, lg: true, xl: true, xxl: false };
      break;
    case BreakpointEnum.lg:
      breakpoints = { xs: false, sm: true, md: true, lg: true, xl: false, xxl: false };
      break;
    case BreakpointEnum.md:
      breakpoints = { xs: false, sm: true, md: true, lg: false, xl: false, xxl: false };
      break;
    case BreakpointEnum.sm:
      breakpoints = { xs: false, sm: true, md: false, lg: false, xl: false, xxl: false };
      break;
    case BreakpointEnum.xs:
      breakpoints = { xs: true, sm: false, md: false, lg: false, xl: false, xxl: false };
      break;
  }

  return useResponsiveByBreakpoints(responsiveInput, breakpoints);
}

export { useResponsive };

export function useResponsiveByRef<T>(responsiveInput: ResponsiveInput<T>) {
  const [ref, breakpoint] = useBreakpointRef();
  return [ref, useResponsive(responsiveInput, breakpoint)];
}
