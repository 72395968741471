

const appScroll = () => document.getElementById('app');

const smoothToTop = () => {
  appScroll().scrollTo({top: 0, left: 0, behavior: 'smooth' });
};

function infiniteLoad(load, scroll = appScroll(), isFetched) {

  let previousHeight = 0;

  function onScroll() {

    if (scroll.scrollHeight !== previousHeight && (scroll.scrollTop + scroll.offsetHeight + 300 > scroll.scrollHeight)) {
      load();
      previousHeight = scroll.scrollHeight;
    }

  }

  if (isFetched && scroll.scrollTop === 0 && scroll.offsetHeight === scroll.scrollHeight) {
    load();
    previousHeight = scroll.scrollHeight;
  }

  scroll.addEventListener('scroll', onScroll);

  return () => {
    scroll.removeEventListener('scroll', onScroll);
  };
}

const Scroll = {
  smoothToTop,
  infiniteLoad
};

export default Scroll;
