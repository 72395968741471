import dayjs from 'dayjs';
import { t } from 'src/i18n/i18next';

const DateTimeKit = {};

DateTimeKit.MAX_DATE = '9999-12-31';
DateTimeKit.MIN_DATE = '0000-01-01';
DateTimeKit.TIME_HM = () => `H[${t('global.time.hoursShort')}] mm[${t('global.time.minutesShort')}]`; // 12h 34m

DateTimeKit.isSecondPartInFirst = (fromDate1, toDate1, fromDate2, toDate2) => {
  let intersected = false;
  if (fromDate1 && toDate1) {
    intersected ||= fromDate2 && dayjs(fromDate2).isBetween(fromDate1, toDate1, null, '[]');
    intersected ||= toDate2 && dayjs(toDate2).isBetween(fromDate1, toDate1, null, '[]');
  }

  return intersected;
};

DateTimeKit.isIntersectPeriods = (fromDate1, toDate1, fromDate2, toDate2) => (
  DateTimeKit.isSecondPartInFirst(fromDate1, toDate1, fromDate2, toDate2) ||
  DateTimeKit.isSecondPartInFirst(fromDate2, toDate2, fromDate1, toDate1)
);

DateTimeKit.formatTimeDuration = (value = 0, unit = 'minutes', format = 'H:mm') => {
  value = Number(value);
  if (Number.isNaN(value)) {
    value = 0;
  }
  if (value < 0) {
    format = '-' + format;
  }
  const durationValue = dayjs.duration(Math.abs(value), unit);
  return durationValue.format(format.replace(/H+/, Math.floor(durationValue.asHours())));
};

DateTimeKit.formatTimeDurationDecimalHours = (value = 0, unit = 'minutes', trim = 2) => {
  if (value) {
    return dayjs.duration(value, unit).asHours().toFixed(trim).replace('.00', '');
  } else {
    return '0';
  }
};

DateTimeKit.simpleTimeToMinutes = (time, maxHours) => {
  const [hours, minutes] = time.split(':');
  const isNegative = hours.startsWith('-');
  const res = +minutes + Math.abs(hours === '-' ? 0 : hours) * 60;

  if (res >= maxHours * 60) {
    return maxHours * 60 * (isNegative ? -1 : 1);
  } else {
    return res * (isNegative ? -1 : 1);
  }
};

DateTimeKit.smartHoursToMinutes = (hours, maxHours) => {
  const sourceHours = hours;
  hours = +hours.replace(',', '.');
  if ((!maxHours || Math.abs(hours) <= maxHours) && !(sourceHours.startsWith(0) || sourceHours.startsWith('-0'))) {
    return hours * 60;
  }
  const time = ('' + hours).split('');
  time.splice(-2, 0, ':');

  return DateTimeKit.simpleTimeToMinutes(time.join(''));
};

DateTimeKit.smartStringToMinutes = (maxHours, value) => {
  return value.includes(':') ? DateTimeKit.simpleTimeToMinutes(value, maxHours) : DateTimeKit.smartHoursToMinutes(value, maxHours);
};

DateTimeKit.dateFormatListWeeks = ['YYYY-WW', 'YYYY-W'];
DateTimeKit.dateFormatListMonths = ['YYYY MMM', 'YYYY-MM', 'YYYY-M', 'MM', 'M'];

const memoizedFormats = new Map();
DateTimeKit.memoizeFormat = (date, format) => {
  const key = format + date;
  if (!memoizedFormats.has(key)) {
    memoizedFormats.set(key, dayjs(date).format(format));
  }
  return date ? memoizedFormats.get(key) : null;
};

DateTimeKit.resetMemoizedFormats = () => {
  memoizedFormats.clear();
};

export default DateTimeKit;
