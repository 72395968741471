import { useState } from 'react';
import { useEffectOnce, useIsomorphicLayoutEffect } from 'react-use';
import { IHookStateInitAction, IHookStateSetAction, resolveHookState } from 'react-use/lib/misc/hookState';

// Copy of the react-use hook with fix until this PR is merged https://github.com/streamich/react-use/pull/2564
export function createGlobalState<S = never>(
  initialState: IHookStateInitAction<S>
): () => [S, (state: IHookStateSetAction<S>) => void];
export function createGlobalState<S = undefined>(): () => [
  S,
  (state: IHookStateSetAction<S>) => void
];

export function createGlobalState<S>(initialState?: S) {
  const store: {
    state: S;
    setState: (state: IHookStateSetAction<S>) => void;
    setters: ((s: S) => void)[];
  } = {
    state: initialState instanceof Function ? initialState() : initialState,
    setState(nextState: IHookStateSetAction<S>) {
      store.state = resolveHookState(nextState, store.state);
      store.setters.forEach((setter) => setter(store.state));
    },
    setters: []
  };

  return () => {
    const [globalState, stateSetter] = useState<S | undefined>(store.state);

    useEffectOnce(() => () => {
      store.setters = store.setters.filter((setter) => setter !== stateSetter);
    });

    useIsomorphicLayoutEffect(() => {
      if (!store.setters.includes(stateSetter)) {
        store.setters.push(stateSetter);
        stateSetter(store.state); // state might be set before setter is pushed, but after default state initialized
      }
    });

    return [globalState, store.setState];
  };
}

export default createGlobalState;
