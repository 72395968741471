export enum Method {
  Post = 'post',
  PostOne = 'post_one',
  PostBatch = 'post_batch',
  Put = 'put',
  PutOne = 'put_one',
  Patch = 'patch',
  PatchOne = 'patch_one',
  Delete = 'delete',
  DeleteOne = 'delete_one'
}

export enum SortOrder { asc = 'asc', desc = 'desc' }

export enum AddPosition { start, end}
