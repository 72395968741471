import { message } from 'antd';
import { useMemo } from 'react';
import { formatBackendErrorMessage, t } from 'src/i18n/i18next';
import { ErrorData, QueryKeyStrict } from '../api/types';
import { Method } from '../api/localEnums';
import { Messages, MessagesConfig, MessagesCallbacks } from 'src/types/useStatusMessage';

const loadingTimeout = 5 * 60 * 1000; // 5 minutes

const getDefaultQueryMessages = () => ({
  loading: t('global.messages.loading'),
  success: t(`queries.success.get`),
  error: t(`queries.error.fetch`)
});

const getDefaultMutationMessages = (method: Method) => ({
  loading: t('queries.processing'),
  success: t([`queries.success.${method}`, `queries.success.${method.split('_').shift()}`]),
  error: (err: ErrorData) => formatBackendErrorMessage(err, t([`queries.error.${method}`, `queries.error.${method.split('_').shift()}`], err.arguments))
});

const loadingMessageTimeouts = {};

const clearKey = (key: string) => {
  clearTimeout(loadingMessageTimeouts[key]);
  delete loadingMessageTimeouts[key];
};

const resetAll = () => Object.keys(loadingMessageTimeouts).forEach((key) => {
  clearKey(key);
  message.destroy(key);
});

const requestMessages = ({ messages, key, silent }: { messages: Messages, key: QueryKeyStrict, silent: boolean }): MessagesCallbacks => {

  const strKey = JSON.stringify(key);

  return ({
    loadingMessage: silent ? () => {} : (config = {}) => {

      const msg = messages.loading;
      if (!msg || loadingMessageTimeouts[strKey]) { // in case of calling loadingMessage multiple times
        return;
      }

      loadingMessageTimeouts[strKey] = setTimeout(message.loading.bind(null, {
        content: typeof msg === 'string' ? msg : msg() || msg,
        key: strKey,
        duration: loadingTimeout,
        ...config
      }), 300);
    },
    successMessage: silent ? () => {
    } : (config = {}) => {
      clearKey(strKey);
      const msg = messages.success;
      msg ? message.success({ content: typeof msg === 'string' ? msg : msg(), key: strKey, ...config }) : message.destroy(strKey);
    },
    errorMessage: silent ? () => {
    } : (config = {}, err: { errorObj: ErrorData }) => {
      clearKey(strKey);
      const msg = messages.error;
      msg ? message.error({ content: typeof msg === 'string' ? msg : msg(err.errorObj), key: strKey, ...config }) : message.destroy(strKey);
    },
    resetAll
  });
};

const useStatusMessage = (config: MessagesConfig): MessagesCallbacks => {
  const defaultMessages = config.method ? getDefaultMutationMessages(config.method) : getDefaultQueryMessages();
  const messages = Object.assign({}, defaultMessages, config.messages || {});

  return useMemo(
    requestMessages.bind(null, { messages, key: config.key, silent: config.silent }),
    [messages.success, messages.loading, messages.error, config.key, config.silent]
  );
};

export default useStatusMessage;
