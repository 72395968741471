import React from 'react';
import { matchPath } from 'react-router';
import { Navigate } from 'src/helpers';
import { usePreferences } from 'src/hooks';

export function isBackboneLoaded() {
  const { app } = window;
  return !!(app && app.security && app.security.hasPermission && app.security.staticPermissions && !app.security.placeholder);
}

export function hasAccessTo(route, hasRuntimePermission) {

  if (route.hideIn) {
    if (route.hideIn.split(',').some(p => p === window.location.hostname.split('.')[0])) {
      return false;
    }
  }

  const { app, _ } = window; // from backbone

  if (route.requireRule) {
    if (!route.requireRule()) {
      return false;
    }
  }

  if (route.requirePreference) {
    if (_.isArray(route.requirePreference)) {
      for (const preference of route.requirePreference) {
        if (!app.prefs.getBoolean(preference)) {
          return false;
        }
      }
    } else if (_.isObject(route.requirePreference)) {
      if (!app.prefs.getBoolean(route.requirePreference.key, route.requirePreference.defaultValue, route.requirePreference.scope)) {
        return false;
      }
    } else {
      if (!app.prefs.getBoolean(route.requirePreference)) {
        return false;
      }
    }
  }

  if (route.requirePermission) {
    if (hasRuntimePermission) {
      return true;
    }
    if (typeof route.requirePermission === 'function') {
      const checkSubRoutes = () => (route.routes || []).filter((subRoute) => !subRoute.isCollapsedTitle).some((subRoute) => hasAccessTo(subRoute, hasRuntimePermission));
      if (!route.requirePermission(app.security, checkSubRoutes)) {
        return false;
      }
    } else {

      const requirePermissions = route.requirePermission.split(',');
      if (!requirePermissions.some(p => app.security.hasPermission(p))) {

        if (!route.dynamicPermission) {
          return false;
        }

        const dynamicPermissions = route.dynamicPermission.split(',');
        if (!dynamicPermissions.some(p => app.security.hasDynamicPermission(p))) {
          return false;
        }
      }
    }
  }

  return true;
}

export function filterRoutes(routes = [], matchParams, hasRuntimePermission, routesOrderPreference) {
  let filteredRoutes = [];

  for (const route of routes) {
    if (hasAccessTo(route, hasRuntimePermission)) {
      if (hasRuntimePermission && !hasRuntimePermission(route.requirePermission, matchParams)) {
        continue;
      }
      const filtered = Object.assign({}, route);
      if (filtered.routes) {
        filtered.routes = filterRoutes(filtered.routes, matchParams, route.hasRuntimePermission || hasRuntimePermission, filtered.routesOrderPreference);
      }
      filteredRoutes.push(filtered);
    }
  }

  const routesToRender = new Set(filteredRoutes.map(r => r.route).filter(r => !!r));

  if (routesOrderPreference) {
    const allowOverrideRoutes = app.prefs.get('app.routing.allowOverrideRoutes') || 'allowOverride';
    const routesOrderString = (allowOverrideRoutes === 'allowOverride') ? app.prefs.get(routesOrderPreference) : app.prefs.get(routesOrderPreference, 'company');

    if (routesOrderString) {
      const routesOrder = routesOrderString.split(',');
      if (routesOrder?.length > 1) {
        const orderedRoutes = [];
        const addRoute = route => {
          const foundRoute = filteredRoutes.find(r => r.route === route);
          if (foundRoute && !orderedRoutes.includes(foundRoute)) {
            orderedRoutes.push(foundRoute);
          }
        };
        const collapsedRoute = filteredRoutes.find(r => r.isCollapsedTitle);
        collapsedRoute && orderedRoutes.push(collapsedRoute);
        for (const route of routesOrder) {
          if (route.charAt(0) !== '!') {
            addRoute(route);
          }
          routesToRender.delete(route.replace(/^!/, ''));
        }
        if (routesToRender.size > 0) {
          routesToRender.forEach(addRoute);
        }
        if (orderedRoutes.length >= 1) {
          filteredRoutes = orderedRoutes;
        }
      }
    }
  }

  return filteredRoutes;
}

let uniqueKey = 0;

/* eslint-disable max-statements */
function buildConfigRoutes(parentPath, routes, parentItem, options) {

  const {
    onlyStatic = false,
    withActions = false,
    withInjections = false,
    forcePath = false,
    withBreadcrumbName = false,
    withCollapsedTitles = false,
    withFirstChildPath = false
  } = options;

  const items = [];

  for (const route of routes) {

    const path = route.route || '';

    if (onlyStatic && !route.isStatic) {
      const isDynamic = path.indexOf(':') >= 0;
      if (isDynamic || !route.title) {
        continue;
      }
    }

    const absolutePath = path[0] === '/' ? path : parentPath + (path.length === 0 ? path : '/' + path);

    // NB! any additional fields will broke ProLayout
    const item = {
      icon: route.icon,
      name: route.title
    };

    if (route.isBeta) {
      item.isBeta = route.isBeta;
    }

    if (route.hidden) {
      item.hidden = route.hidden;
    }

    if (route.isOld) {
      item.isOld = route.isOld;
    }

    if (route.dataTestId) {
      item.dataTestId = route.dataTestId;
    }

    if (route.isCollapsedTitle) {
      if (withCollapsedTitles) {
        item.isCollapsedTitle = true;
      } else {
        continue;
      }
    }

    if (options.withParent) {
      item.parent = parentItem;
    }

    if (withBreadcrumbName) {
      item.breadcrumbName = uniqueKey++;
    }

    assignRouteFields(item, route, options);

    if ('overrideActions' in route) {
      item.overrideActions = route.overrideActions;
    }

    if (withActions && route.menuActions) {
      item.menuActions = route.menuActions.filter(action => hasAccessTo(action));
    }

    if (withInjections && route.injections) {
      item.injections = route.injections.filter(action => hasAccessTo(action));
    }

    if (route.routes && route.routes.length > 0) {
      item.children = buildConfigRoutes(absolutePath, route.routes, item, options);
    }

    if (withFirstChildPath) {
      let firstChildPath = null;
      let current = item;
      while (current.children?.length > 0 && !firstChildPath) {
        let firstChild = current.children[0];

        if (firstChild.isCollapsedTitle) {
          firstChild = current.children[1];
        }

        if (!firstChild) {
          break;
        }

        firstChildPath = firstChild.path;
        current = firstChild;
      }
      item.firstChildPath = firstChildPath;
    }

    if (forcePath || !route.routes || route.routes.length === 0) {
      item.path = absolutePath;
    }

    items.push(item);
  }

  return items;
}
/* eslint-enable max-statements */

function assignRouteFields (target, route, options) {

  if (options.withHideContentMenu && route.hideContentMenu) {
    target.hideContentMenu = route.hideContentMenu;
  }

  if (options.withContextMenu && route.contextMenu) {
    target.contextMenu = route.contextMenu;
  }

  if (options.withEntitySelector && route.entitySelector) {
    target.entitySelector = route.entitySelector;
  }

}

export function buildRoutes(parentPath, routes, options = {}) {
  let items = buildConfigRoutes(parentPath, routes, undefined, options);
  if (options.withCollapsedTitles) {
    // checking only first lever for now
    // ignoring routes only with collapsedTitles
    items = items.filter(i => (i.children && i.children.length > 1) || (i.path && i.path.indexOf('analytics') >= 0));
  }

  return items;
}

export function findFlatRouteByPathname(routes = [], pathname) {

  for (const route of sortRoutes(routes)) {
    const match = matchPath(pathname, { path: route.path });
    if (match) {
      return route;
    }
  }

  return null;
}

export function matchRouteByPathname(routes = [], pathname) {

  const matchRoute = (routes, matchedRoutes) => {
    for (const route of routes) {
      const match = matchPath(pathname, { path: route.path });
      if (route.children) {
        matchRoute(route.children, matchedRoutes);
      }

      if (match) {
        matchedRoutes.push([route, match]);
      }
    }
  };

  const matchedRoutes = [];

  matchRoute(routes, matchedRoutes);

  matchedRoutes.sort(([a], [b]) => {
    if (a.path === b.path) {
      return a.children ? 1 : -1;
    }
    return a.path.length > b.path.length ? -1 : 1;
  });

  return matchedRoutes[0] || [];
}

function sortRoutes(routes = []) {
  const sorted = [...routes];
  sorted.sort((a, b) => b.path.length - a.path.length);
  return sorted;
}

export function filterRoutesInto(result = [], routes = [], check) {

  for (const route of routes) {

    if (check(route)) {
      result.push(route);
    }
    if (route.children) {
      filterRoutesInto(result, route.children, check);
    }
  }
}

export function redirectToLogin(redirectPathAfterLogin) {
  window.location.href = Navigate.link.toLogin(redirectPathAfterLogin);
}

export function redirectToLogout() {
  window.location.assign(Navigate.link.toLogout());
}

export function redirectToSignup() {
  window.location.assign(Navigate.link.toSignup());
}

export function redirectToSuccessCreateCompany() {
  window.location.assign(Navigate.link.toSuccessCreateCompany());
}

export function resolveUrlParams(pathname, path) {

  const lastParamSlash = path.indexOf('/', path.lastIndexOf(':') + 1);
  let paramsPath = path.substr(0, lastParamSlash === -1 ? undefined : lastParamSlash);

  // HACK to support tibet navigation in travel and expenses
  if (paramsPath.indexOf('/claims/:overview') > -1) {
    paramsPath = paramsPath.replace('/claims/:overview', '');
  }

  const match = matchPath(pathname, { path: paramsPath });

  let newPath = path;
  if (match && match.params) {
    for (const key in match.params) {
      const value = match.params[key];
      if (!value) {
        newPath = newPath.replace('/:' + key + '?', '');
      }
      newPath = newPath.replace(':' + key + '?', value);
      newPath = newPath.replace(':' + key, value);
    }
  }

  return newPath;
}


export function resolveComplexRoutePath(route) {

  if (route.children?.length > 0) {
    return route.children[0].path || resolveComplexRoutePath(route.children[0]);
  }

  return route.path;
}

// backbone fallback TODO rewrite to store state on react side (mobx?)
export function canLeaveCurrentView() {
  return window.tb.app.navigation.events.unconfirmedChanges.length > 0;
}

export function cleanUnconfirmedChanges() {
  return window.tb.app.navigation.events.unconfirmedChanges = [];
}

// Wrapper to ensure we're not rendering component even when the toggle button is not shown
export const withPreference = (Component, { requirePreference }, FallbackComponent = () => null) => {
  return (props) => {
    const preferences = usePreferences();
    const hasPreference = !requirePreference || preferences[requirePreference] === 'true';
    return hasPreference ? <Component {...props} /> : <FallbackComponent {...props} />;
  };
};
