import useOnMount from 'src/hooks/useOnMount';


function useInstantFocus(inputRef, focus = true) {

  // NB! Don't update focus instantly, it cause react error, if input ref is a component
  // if (focus && inputRef.current) {
  //   inputRef.current.focus();
  // }

  useOnMount(() => {

    let mounted  = true;

    const updateFocus = () => {

      if (focus && inputRef.current) {
        inputRef.current.focus();
        return;
      }

      if (mounted) {
        setTimeout(updateFocus, 50);
      }
    };

    updateFocus();

    return () => {
      mounted = false;
    };
  });

}


export default useInstantFocus;
