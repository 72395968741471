import { useEffect, useState } from 'react';

const useLocalStorage = (key, defaultValue) => {
  const getValueFromLocalStorage = () => {
    const value = localStorage.getItem(key);
    try {
      return value ? JSON.parse(value) : defaultValue;
    } catch (e) {
      console.error('Could not parse string by key: ', key, ', and value: ', value);
      return defaultValue;
    }
  };


  const [value, setValue] = useState(getValueFromLocalStorage);

  const get = () => {
    setValue(getValueFromLocalStorage());
  };

  const set = value => {
    localStorage.setItem(key, JSON.stringify(value));
    window.dispatchEvent(new window.CustomEvent(key));

    setValue(value);
  };

  const reset = () => {
    set(defaultValue);
  };

  useEffect(() => {
    window.addEventListener(key, get);

    return () => {
      window.removeEventListener(key, get);
    };
  }, []);

  return [value, get, set, reset];
};

export default useLocalStorage;
