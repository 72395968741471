import { useRef } from 'react';
import { useOnMount } from 'src/hooks/index';

/**
 * Sets value to a constant once on mount; blocks render
 * use {@link useLazyConstant} if you do not want to block render until constant is set
 * @param {any|function} factory
 * @returns {any} result of the factory or factory itself if not a function
 */
export const useConstant = (factory) => {
  const initRef = useRef(false);
  const valueRef = useRef();

  if (!initRef.current) {
    initRef.current = true;
    valueRef.current = typeof factory === 'function' ? factory() : factory;
  }

  return valueRef.current;
};

/**
 * Sets value to a constant once on mount; does not block render
 * use {@link useConstant} if you want to block render until constant is set
 * @param {any} factory
 * @returns {any} result of the factory or factory itself if not a function
 */
export const useLazyConstant = (factory) => {
  const valueRef = useRef();

  useOnMount(() => {
    valueRef.current = typeof factory === 'function' ? factory() : factory;
  });

  return valueRef.current;
};
