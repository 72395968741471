
export function mapKeysToUrls(object, parentUrl = '') {
  Object.defineProperty(object, 'toString', { value: () => object._ || parentUrl });
  for (const key in object) {
    const url = parentUrl + mapKeyToUrl(key);
    const value = object[key];
    if (typeof value === 'string') {
      object[key] = url;
    } else {
      mapKeysToUrls(object[key], url);
    }
  }
}

const ROOT = '_';

function mapKeyToUrl(key) {

  if (key === ROOT) {
    return '';
  }

  return '/' + key
    .replace(new RegExp('\\$', 'g'), ':')
    .replace(new RegExp('__', 'g'), '.')
    .replace(new RegExp('_', 'g'), '-');
}

// enums can be array also
export function buildEnums(enums) {

  for (const enumName in enums) {

    const enumObject = enums[enumName];

    for (const enumValue in enumObject) {
      if (enumValue[0] !== '_') {
        enumObject[enumValue] = enumValue;
      }
    }

  }
}

export function groupBy(input, key) {
  return input.reduce((acc, currentValue) => {
    const groupKey = currentValue[key];
    if (!acc[groupKey]) {
      acc[groupKey] = [];
    }
    acc[groupKey].push(currentValue);
    return acc;
  }, {});
}

export function toObject(input, key) {
  return input.reduce((acc, currentValue) => {
    const groupKey = currentValue[key];
    if (!acc[groupKey]) {
      acc[groupKey] = [];
    }
    acc[groupKey] = {...currentValue};
    return acc;
  }, {});
}

export const mapReducer = (key, clone = false) => (memo, item) => {
  memo[item[key]] = clone ? { ...item } : item;
  return memo;
};
