const regexes = {
  boolean: /(true|false)/,
  html: /<[a-z/][\s\S]*>/,
  markdown: /\*\*(.*)\*\*|\*(.*)\*|^### (.*$)|^## (.*$)|^# (.*$)|^> (.*$)|!\[(.*?)\]\((.*?)\)|\[(.*?)\]\((.*?)\)|\n$/,
  color: {
    hex: /^#([0-9A-F]{3}){1,2}$/i,
    rgb: /^rgba?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i
  },
  filenameReservedCharacters: /[/\\?%*:|"<>\s*]/g,
  decimals: /[.|,][0-9]+/g
};

const isValidStringInput =  value => value && typeof value === 'string';

const matchesBoolean = value => value && value.match(regexes.boolean);

const matchesHTML = value => isValidStringInput(value)
  ? value.match(regexes.html)
  : false;

const matchesMarkdown = value => isValidStringInput(value)
  ? value.match(regexes.markdown)
  : false;

const matchesHexColor = value => value && typeof value === 'string'
  ? value.match(regexes.color.hex)
  : false;

const matchesRgbColor = value => value && typeof value === 'string'
  ? value.match(regexes.color.rgb)
  : false;

const replaceFilenameReservedCharacters = (value, replacer) => isValidStringInput(value)
  ? value.replace(regexes.filenameReservedCharacters, replacer)
  : value;

const replaceDecimals = (value, replacer) => isValidStringInput(value)
  ? value?.replace(regexes.decimals, replacer)
  : value;

const getAttachments = value => {
  const inlineAttachments = value.matchAll(/\/files\/(\d+)(?=\/content\/)/g) || [];
  const attachmentIds = new Set();
  for (const item of inlineAttachments) {
    attachmentIds.add(+item[1]);
  }
  return [...attachmentIds];
};

const RegexKit = {
  matchesBoolean,
  matchesHTML,
  matchesMarkdown,
  matchesHexColor,
  matchesRgbColor,
  replaceFilenameReservedCharacters,
  replaceDecimals,
  getAttachments
};

export default RegexKit;
