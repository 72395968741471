
import { useState } from 'react';
import { useMountedState } from 'react-use';


function useSafeState(initialState) {

  const isMounted = useMountedState();
  const [state, setState] = useState(initialState);

  const setSafeState = (value) => {
    if (isMounted()) {
      setState(value);
    }
  };

  return [state, setSafeState];
}

export default useSafeState;
