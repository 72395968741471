import { cApi } from './Api';
import Identity from './Identity';
import { Url } from 'src/helpers';
import Path from 'src/helpers/Path';
import RequestError from './RequestError';

function  makeFetch (method, pathname, options = {}, suffix, body) {

  if ((options.body || body) && method.toUpperCase() === 'GET') {
    throw new Error('body must be undefined for GET requests');
  }

  const expectedOptionsKeys = ['apiBase', 'params', 'query', 'headers', 'credentials', 'contentType', 'cache', 'redirect', 'referrerPolicy', 'mode'];

  for (const key in options) {
    if (!expectedOptionsKeys.includes(key)) {
      throw new Error(`Invalid key found in request options or wrong position for parameter: "${key}"`);
    }
  }

  const {
    apiBase = CONFIG.apiUrl,
    params = {}, // url path params like :company, :id etc. without ':'
    query = {},
    headers,
    credentials = 'include',
    contentType = 'application/json',
    cache = 'no-cache',
    redirect = 'follow',
    referrerPolicy = 'no-referrer-when-downgrade',
    mode = 'cors'
  } = options;


  if (options.body) {
    body = Object.assign(body || {}, options.body);
  }

  if (pathname._) {
    pathname = pathname._;
  }
  if (suffix === null) {
    console.error(`Id is undefined. Make sure to put it to 'enabled' flag of react-query config`);
  }
  if (suffix) {
    pathname = Path.join(pathname.toString(), suffix);
  }

  if (pathname.indexOf(cApi._) === 0 && !params.company) { // checking first to make safe get company code
    params.company = Identity.companyCode;
  }
  pathname = Path.applyParams(pathname, params);

  const url = new URL(apiBase, document.location);
  url.pathname = Path.join(url.pathname, pathname);
  const sort = query.sort;
  Url.updateQuery(url, query);
  if (Array.isArray(sort)) {
    url.searchParams.delete('sort');
    sort.forEach(sortPart => url.searchParams.append('sort', sortPart));
  }

  const fetchHeaders = new Headers();
  if (headers) {
    Object
      .keys(headers)
      .forEach(name => fetchHeaders.append(name, headers[name]));
  }

  if (contentType && (!headers || !headers['Content-Type'])) {
    fetchHeaders.append('Content-Type', contentType);
  }

  const isJson = contentType.includes('json');
  const fetchBody = body && isJson ? JSON.stringify(body) : body;

  const controller = new AbortController();

  // NB! fetch expecting preferably string as first parameter (in tests fails)
  let promise = fetch(url.toString(), {
    headers: fetchHeaders,
    method,
    credentials,
    cache,
    redirect,
    referrerPolicy,
    mode,
    body: fetchBody,
    signal: controller.signal
  });

  // handling of non throwable errors
  promise = promise.then(r => {

    if (r.ok) {
      return r;
    }

    if (r.json) {
      return r.json()
        .catch(() => {
          throw new RequestError(r.status, r.statusText || r.status + ', url: ' + r.url, r);
        })
        .then(json => {
          throw new RequestError(r.status, json.exception + ': ' + json.message + ', url: ' + r.url, json);
        });
    }

    throw new RequestError(r.status, r.statusText || r.status + ', url: ' + r.url, r); // http/2 does not have statusText
  });

  promise = promise.then(r => {
    if (contentType.includes('json') && r.headers.get('Content-Type')?.includes('json')) {
      return r.json();
    }
    if (contentType.startsWith('application/pdf')) {
      return r.blob();
    }
    return r.text();
  });

  promise.catch((error) => {
    if (error && error.code === 20) {
      // AbortError is not "real" error and should be ignored
      console.log('Promise was aborted');
    } else {
      throw error;
    }
  });

  // making promise globally cancelable (used code from react-query docs)
  promise.cancel = () => controller.abort();

  return promise;
}

const joinParts = (parts = []) => typeof parts === 'string' ? parts : parts.join('+');

const fetchFn = (apiUrl, options = {}) => makeFetch(options.method, apiUrl, options, options.suffix);

const getFn = (apiUrl, options = {}) => makeFetch('GET', apiUrl, options);

getFn.count = (apiUrl, options = {}) => makeFetch('GET', apiUrl, options, 'count');

getFn.one = (apiUrl, id = null, options = {}) => makeFetch('GET', apiUrl, options, id);
getFn.all = getFn; // alias

getFn.include = (apiUrl, parts, options = {}) => makeFetch('GET', apiUrl, options, Path.join('include', joinParts(parts)));
getFn.include.one = (apiUrl, id = null, parts, options = {}) => makeFetch('GET', apiUrl, options, Path.join(id, 'include', joinParts(parts)));
getFn.include.all = getFn.include;

getFn.report = (apiUrl, options = {}) => makeFetch('GET', apiUrl, options, 'getReport');

const postFn = (apiUrl, body, options) => makeFetch('POST', apiUrl, options, undefined, body);

postFn.one = (apiUrl, model, options = {}) => makeFetch('POST', apiUrl, options, undefined, model);
postFn.include = (apiUrl, parts, model, options = {}) => makeFetch('POST', apiUrl, options, Path.join('include', joinParts(parts)), model);

postFn.batch = (apiUrl, batchObject, options = {}) =>  makeFetch('POST', apiUrl, options, 'batch', batchObject);
postFn.batch.include = (apiUrl, parts, batchObject, options = {}) =>  makeFetch('POST', apiUrl, options, Path.join('batch', 'include', joinParts(parts)), batchObject);

const putFn = (apiUrl, body, options) => makeFetch('PUT', apiUrl, options, undefined, body);

putFn.one = (apiUrl, id = null, model, options = {}) => makeFetch('PUT', apiUrl, options,  id, model);
putFn.include = function () {};
putFn.include.one = (apiUrl, id = null, parts, model, options = {}) => makeFetch('PUT', apiUrl, options, Path.join(id, 'include', joinParts(parts)), model);

const patchFn = (apiUrl, body, options = {}) => makeFetch('PATCH', apiUrl, options, undefined, body);

patchFn.one = (apiUrl, id = null, model, options = {}) => makeFetch('PATCH', apiUrl, options,  id, model);
patchFn.include = function () {};
patchFn.include.one = (apiUrl, id = null, parts, model, options = {}) => makeFetch('PATCH', apiUrl, options, Path.join(id, 'include', joinParts(parts)), model);

const deleteFn = (apiUrl, body, options) => makeFetch('DELETE', apiUrl, options, undefined, body);

deleteFn.one = (apiUrl, id = null, options = {}) => makeFetch('DELETE', apiUrl, options, id);

deleteFn.byIds = (apiUrl, ids = [], options = {}) => makeFetch('POST', apiUrl, options, 'batch', { delete: ids });

// Make fetch-promises (instantly)
const Make = {
  get: getFn,
  post: postFn, // crete
  put: putFn, // update
  patch: patchFn,
  delete: deleteFn,
  fetch: fetchFn
};

/**
 *
 * @param {*[]} apiUrl
 * @param {object} obj
 * @param {string[]} [parts]
 * @param {number|string} [id]
 * @returns {string[]}
 */
const composeKey = (apiUrl, { params, query }, parts, id) => {
  const key = [apiUrl.toString()];
  if (id !== undefined) {
    key.push('id:' + id);
  }
  if (params !== undefined) {
    key.push('params:' + JSON.stringify(params));
  }
  if (parts !== undefined) {
    key.push('include:' + parts);
  }
  if (query !== undefined) {
    key.push('query:' + JSON.stringify(query));
  }

  return key;
};

const fetchFnCb = (apiUrl, options = {}) => ({
  key: composeKey(apiUrl, options),
  request: () => fetchFn(apiUrl, options)
});

const getFnCb = (apiUrl, options = {}) => ({
  key: composeKey(apiUrl, options),
  request: (query = options.query) => {
    return getFn(apiUrl, { ...options, query });
  },
  initialQuery: options.query
});

getFnCb.count = (apiUrl, options = {}) => ({
  key: composeKey(apiUrl + '/count', options),
  request: () => getFn.count(apiUrl, options)
});

getFnCb.one = (apiUrl, id, options = {}) => ({
  key: composeKey(apiUrl, options, undefined, id || 0),
  request: () => getFn.one(apiUrl, id, options)
});
getFnCb.all = getFnCb; // alias

getFnCb.include = (apiUrl, parts = [], options = {}) => ({
  key: composeKey(apiUrl, options, parts),
  request: (query = options.query) => {
    return getFn.include(apiUrl, parts, { ...options, query });
  },
  initialQuery: options.query
});
getFnCb.include.one = (apiUrl, id, parts = [], options = {}) => ({
  key: composeKey(apiUrl, options, parts, id || 0),
  request: () => getFn.include.one(apiUrl, id || 0, parts, options)
});
getFnCb.include.all = (apiUrl, parts = [], options = {}) => getFnCb.include(apiUrl, parts, options);

getFnCb.report = (apiUrl, options = {}) => ({
  key: composeKey(apiUrl + '/getReport', options),
  request: (query = options.query) => getFn.report(apiUrl, { ...options, query }),
  initialQuery: options.query
});

const postFnCb = (apiUrl, options = {}) => ({
  method: 'post',
  key: composeKey(apiUrl, options),
  request: body => postFn(apiUrl, body, options)
});

postFnCb.one = (apiUrl, options = {}) => ({
  method: 'post_one',
  key: composeKey(apiUrl, options, undefined, 'new'),
  request: model => postFn.one(apiUrl, model, options)
});
postFnCb.include = function () {};
postFnCb.include.one = (apiUrl, parts = [], options = {}) => ({
  method: 'post_one',
  key: composeKey(apiUrl, options, parts, 'new'),
  request: model => postFn.include(apiUrl, parts, model, options)
});

postFnCb.batch = (apiUrl, options = {}) => ({
  method: 'post_batch',
  key: composeKey(apiUrl, options),
  request: batchObject => postFn.batch(apiUrl, batchObject, options)
});

postFnCb.batch.include = (apiUrl, parts, options = {}) => ({
  method: 'post_batch',
  key: composeKey(apiUrl, options, parts),
  request: batchObject => postFn.batch.include(apiUrl, parts, batchObject, options)
});


const putFnCb = (apiUrl, options = {}) => ({
  method: 'put',
  key: composeKey(apiUrl, options),
  request: body => {
    if (apiUrl === cApi.preferences.$scope) {
      throw 'You should use put.one and treat scope as id';
    }
    return putFn(apiUrl, body, options);
  }
});

putFnCb.one = (apiUrl, id, options = {}) => ({
  method: 'put_one',
  key: composeKey(apiUrl, options, undefined, id || 0),
  request: model => putFn.one(apiUrl, model.id || id, model, options)
});
putFnCb.include = function () {};
putFnCb.include.one = (apiUrl, id, parts = [], options = {}) => ({
  method: 'put_one',
  key: composeKey(apiUrl, options, parts, id || 0),
  request: model => putFn.include.one(apiUrl, model.id || id, parts, model, options)
});

const patchFnCB = (apiUrl, options = {}) => ({
  method: 'patch',
  key: composeKey(apiUrl, options),
  request: body => patchFn(apiUrl, body, options)
});

patchFnCB.one = (apiUrl, id, options = {}) => ({
  method: 'patch_one',
  key: composeKey(apiUrl, options, undefined, id || 0),
  request: model => patchFn.one(apiUrl, model.id || id, model, options)
});

patchFnCB.include = function () { };

patchFnCB.include.one = (apiUrl, id, parts = [], options = {}) => ({
  method: 'patch_one',
  key: composeKey(apiUrl, options, parts, id || 0),
  request: model => patchFn.include(apiUrl, model.id || id, parts, model, options)
});

const deleteFnCb = (apiUrl, options) => ({
  method: 'delete',
  key: composeKey(apiUrl, options),
  request: () => deleteFn(apiUrl, options)
});

deleteFnCb.one = (apiUrl, initialId, options = {}) => ({
  method: 'delete_one',
  key: composeKey(apiUrl, options, undefined, initialId || 0),
  request: (id = initialId) => {
    if (typeof id === 'object') {
      throw new Error('Id type is invalid. If you had set the id previously, make sure you calling mutate() without parameters');
    }
    return deleteFn.one(apiUrl, id, options);
  }
});

/**
 * returns object containing key and request for usage through {@link useMakeMutation} and {@link useMakeQuery}
 * */
const MakeCb = {
  get: getFnCb,
  post: postFnCb,
  put: putFnCb,
  patch: patchFnCB,
  delete: deleteFnCb,
  fetch: fetchFnCb
};


const SIZE_ALL = 999; // DO NOT CHANGE, IF YOU NEED MORE -> DON'T GET ALL -> USE SELECT WITH QUERY
const SIZE_OF_PAGE = 30;

const pageable = (page = 0) => ({
  page,
  size: SIZE_OF_PAGE // must be constant to maintain navigation by pages
});

export { Make, MakeCb, SIZE_ALL, SIZE_OF_PAGE, pageable, composeKey };

