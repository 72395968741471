import { pick } from 'lodash';
import { useQueryClient } from 'react-query';
import { AddPosition } from 'src/api/localEnums';

// TODO check mutations with include - it should not overwrite with null existing data
const getNewDataToSet = (k, newData, oldData) => {
  let newDataToSet = newData;
  if (k.some(kPart => typeof kPart === 'string' && kPart.startsWith('include:')) && oldData) {
    const dataKeysToFilter = Object.keys(oldData);
    newDataToSet = pick(newData, dataKeysToFilter);
  }
  return newDataToSet;
};

/**
 * workaround for updating tags object grouped by tagType
 * doesn't looks like this grouped view used at all, should be simplified, at least for react with additional query key
 * @param {object} oldTags
 * @param {object} newData
*/
const updateTagsForReports = (oldTags, newData) => {
  if (Array.isArray(newData.tags) && !Array.isArray(oldTags)) {
    newData.tags = newData.tags.reduce((memo, tag) => {
      memo[tag.tagType] ||= [];
      memo[tag.tagType].push(tag);
      return memo;
    }, {});
  }
};


const updateData = (oldData, k, newData) => {
  if (k.some(kPart => kPart.includes('getReport'))) {
    updateTagsForReports(oldData.tags, newData);
  }
  return Object.assign({}, oldData, getNewDataToSet(k, newData, oldData));
};

const isResponseInvalid = (data, reportContentName) => {
  if (!data) {
    return true; // !enabled queries
  }
  if (typeof data === 'number') {
    return true; // /count request
  }
  if (data.groups || data[reportContentName]) {
    return false; // reports
  }
  if (!data.content && !data.pages) {
    return true; // custom requests TODO get rid of
  }
  return false;
};

// TODO on create - refetch include/id with same sortBy+size+page to get proper order?
// TODO page counts - recalculate by hand or extends from request from TODO above?
const createNewItems = (addToList, newDataMap, queryClient, position) => {
  if (newDataMap.size === 0) {
    return;
  }
  // Adding new item to listed queries
  addToList.forEach(addToSingle => {
    const parsedKey = JSON.parse(addToSingle);
    return queryClient.setQueryData(parsedKey, data => {
      if (isResponseInvalid(data)) {
        return data;
      }
      if (data.pages) { // TODO start if first page (reduce amount of items on current page), end if last, invalidate for now
        setTimeout(() => {
          queryClient.invalidateQueries(parsedKey, { refetchActive: true });
        }, 100);

        return data;
      }
      if (data.page && (position === AddPosition.start && data.page.number !== 0 || position !== AddPosition.start && data.page.number !== data.page.totalPages - 1)) {
        return data; // prepending if first page and appending if last
      }
      newDataMap.forEach(newData => {
        const newDataToSet = getNewDataToSet(parsedKey, newData, data.content[0]);
        if (position === AddPosition.start) {
          data.content.unshift(newDataToSet);
        } else {
          data.content.push(newDataToSet);
        }
      });
      data.content = [...data.content];
      return data;
    });
  });
};

const deleteExistingItems = (deleteFromList, ids, queryClient) => {
  if (ids.size === 0) {
    return;
  }
  // TODO refetch logic on deletion with paginated data
  // Removing existing item from listed queries
  deleteFromList.forEach(deleteFromSingle => {
    const parsedKey = JSON.parse(deleteFromSingle);
    return queryClient.setQueryData(parsedKey, data => {
      if (isResponseInvalid(data)) {
        return data;
      }
      if (data.pages) { // TODO reduce next page or get one single item for current page? Invalidate for now
        // Set wants the actual object (not just a copy), so id has to be converted to array for a comparison like this
        const idsArray = Array.from(ids);
        if (idsArray.some(id => data.pages.some(page => page.content.some(c => c.id.toString() === id.toString())))) {
          let dataUpdated = false;
          ids.forEach(id => {
            data.pages.forEach(page => {
              const oldItemIndex = page.content.findIndex(c => c.id.toString() === id.toString());
              if (oldItemIndex > -1) {
                page.content.splice(oldItemIndex, 1);
                dataUpdated = true;
              }
            });
          });
          if (dataUpdated) {
            data.pages = [...data.pages];
          }
        }

        return data;
      }
      let dataUpdated = false;
      ids.forEach(id => {
        const oldItemIndex = data.content.findIndex(c => c.id.toString() === id.toString());
        if (oldItemIndex > -1) {
          data.content.splice(oldItemIndex, 1);
          dataUpdated = true;
        }
      });
      if (dataUpdated) {
        data.content = [...data.content];
      }
      return data;
    });
  });
};

const updateDataInContent = (content, id, k, newData) => {
  const oldItemIndex = content.findIndex(c => c.id?.toString() === id);
  if (oldItemIndex > -1) {
    content[oldItemIndex] = updateData(content[oldItemIndex], k, newData);
    return true;
  }

  return false;
};

const updateDataInGroup = (responseData, id, k, newData, reportContentName) => {
  return responseData.groups.some(group => {
    if (group[reportContentName]?.length) {
      return updateDataInContent(group[reportContentName], id, k, newData);
    } else if (group.groups) {
      return updateDataInGroup(group, id, k, newData, reportContentName);
    } else {
      return false;
    }
  });
};

const sortBySortOrder = (data, k) => {
  data.sort((a, b) => {
    return k.toString().includes('sortOrder,desc') ? b.sortOrder - a.sortOrder : a.sortOrder - b.sortOrder;
  });
};

const updateExistingItems = (newDataMap, queryClient, allQueryKeys, queryKeys, variables) => {
  if (newDataMap.size === 0) {
    return;
  }

  newDataMap.forEach((newData, id) => {
    // Update single fetch queries
    allQueryKeys.filter(k => k.includes('id:' + id)) // TODO optimize filter to search once per call
      .forEach(k => setTimeout(
        () => queryClient.setQueryData(k, oldData => updateData(oldData, k, newData))
        , 1));
  });

  // Update multiple fetch queries
  queryKeys.forEach(k => setTimeout(() => {
    queryClient.setQueryData(k, data => {
      let dataUpdated = false;
      const reportContentName = k[0]?.split?.('/').pop();
      if (isResponseInvalid(data, reportContentName)) {
        return data;
      }

      const batchSortRequest = variables?.update && Object.keys(Object.values(variables.update)[0])[0] === 'sortOrder';

      if (data.pages) { // infinite queries
        let pageUpdated = -1;

        newDataMap.forEach((newData, id) => {
          pageUpdated = data.pages.findIndex(page => updateDataInContent(page.content, id.toString(), k, newData));
          dataUpdated = pageUpdated >= 0;
        });

        if (dataUpdated) {
          if (batchSortRequest) {
            sortBySortOrder(data.pages[pageUpdated].content, k);
          }

          data.pages[pageUpdated].content = [...data.pages[pageUpdated].content];
          data.pages = [...data.pages];
        }
      } else if (data[reportContentName]?.length) { // ordinary report queries
        newDataMap.forEach((newData, id) => {
          dataUpdated = updateDataInContent(data[reportContentName], id.toString(), k, newData);
        });
        if (dataUpdated) {
          data[reportContentName] = [...data[reportContentName]];
        }
      } else if (data.groups?.length) { // grouped report queries
        newDataMap.forEach((newData, id) => {
          dataUpdated = updateDataInGroup(data, id.toString(), k, newData, reportContentName);
        });
        if (dataUpdated) {
          data.groups = [...data.groups];
        }
      } else if (data.content) { // ordinary requests
        newDataMap.forEach((newData, id) => {
          dataUpdated = updateDataInContent(data.content, id.toString(), k, newData);
        });
        if (dataUpdated) {
          if (batchSortRequest) {
            sortBySortOrder(data.content, k);
          }
          data.content = [...data.content];
        }
      }

      if (!dataUpdated && k.some(kPart => kPart.includes('"sort":'))) {
        // custom sorting may change item position based on data update
        queryClient.invalidateQueries(k, { refetchActive: true });
      }
      return data;
    });
  }, 1));
};

// eslint-disable-next-line valid-jsdoc
/**
 * constructor of the partial update
 * @param {object} obj
 * @param {readonly string[]} [obj.addTo] -  key ot be add created entity to
 * @param {readonly string[]} [obj.deleteFrom] -  key to remove deleted entity from
 * @param {readonly string[]} [obj.updateIn] - key to update entity in
 * @param {AddPosition} [obj.addPosition] - position for adding new entity - start/end of the list
 * @returns {(function(readonly string[], string, *, *): void)} doPartialUpdate - callback that invokes the update
 */
const usePartialUpdates = ({ addTo, deleteFrom, updateIn, addPosition } = {}) => {
  const queryClient = useQueryClient();

  const addToList = new Set();
  if (addTo) {
    addToList.add(JSON.stringify(addTo));
  }
  const deleteFromList = new Set();
  if (deleteFrom) {
    deleteFromList.add(JSON.stringify(deleteFrom));
  }

  return (key, method, newData, variables) => {
    if (!Array.isArray(key) && typeof key === 'object') {
      throw new Error('Invalid key, [string] expected.');
    }
    const allQueryKeys = queryClient.getQueriesData(key[0]).map(q => Array.isArray(q[0]) ? q[0] : [q[0]]);
    if (updateIn) {
      allQueryKeys.push(updateIn);
    }
    const queryKeys = allQueryKeys.filter(k => !k.some(kPart => typeof kPart === 'string' && kPart.startsWith('id:')));
    if (['post_one', 'post_batch'].includes(method) && !addTo) {
      queryKeys.filter(k => !k.some(kPart => typeof kPart === 'string' && kPart.startsWith('query:'))).forEach(k => addToList.add(JSON.stringify(k)));
    }
    if (['delete_one', 'post_batch'].includes(method)) {
      queryKeys.forEach(k => deleteFromList.add(JSON.stringify(k)));
    }

    if (method.endsWith('_one')) {
      const id = (newData?.id || key.find(kPart => typeof kPart === 'string' && kPart !== 'id:0' && kPart.startsWith('id:'))?.substring(3) || variables)?.toString();
      const newDataMap = new Map([[id, newData]]);
      const deleteIds = new Set([id]);
      if (['put_one', 'patch_one'].includes(method)) {
        updateExistingItems(newDataMap, queryClient, allQueryKeys, queryKeys);
      }
      createNewItems(addToList, newDataMap, queryClient, addPosition);
      deleteExistingItems(deleteFromList, deleteIds, queryClient);
    } else if (method === 'post_batch') {
      const updatedDataMap = new Map(newData?.updated?.map(data => [data.id.toString(), data]));
      const createdDataMap = new Map(newData?.created?.map(data => [data.id.toString(), data]));
      const deleteIds = new Set(newData.deleted);
      updateExistingItems(updatedDataMap, queryClient, allQueryKeys, queryKeys, variables);
      createNewItems(addToList, createdDataMap, queryClient, addPosition);
      deleteExistingItems(deleteFromList, deleteIds, queryClient);
    } else {
      console.warn('Not implemented');
    }
  };
};

export default usePartialUpdates;
