import { useQueryClient } from 'react-query';
import useRelatedQueries from 'src/api/useRelatedQueries';
import { Id, QueryKey } from 'src/api/types';

const useInvalidateQueries = (key: QueryKey, refetchActive = false, removeOldCache = false, exact = false, invalidateRelated = true) => {
  const queryClient = useQueryClient();
  const keyToInvalidate = exact ? key : key[0]?.toString();
  const getRelatedRequests = useRelatedQueries();
  if (!keyToInvalidate) {
    return null;
  }

  const invalidate = (keyToInvalidate: QueryKey, refetchActive: boolean) => {
    queryClient.invalidateQueries(keyToInvalidate, { refetchActive });
    if (removeOldCache) {
      queryClient.removeQueries(keyToInvalidate);
    }
  };

  return (id: Id = null, _refetchActive = refetchActive) => {
    invalidate(keyToInvalidate, _refetchActive);
    if (typeof keyToInvalidate === 'string' && invalidateRelated) {
      getRelatedRequests(keyToInvalidate, id).forEach(key => invalidate(key, true));
    }
  };
};

export default useInvalidateQueries;
