import { cApi, MakeCb } from 'src/api';
import { useMakeQuery } from 'src/hooks/useMakeQuery';

const useCompany = () => {
  const { data: company = {} } = useMakeQuery(MakeCb.get(cApi), { refetchOnMount: false, keepPreviousData: true });
  company.isMomentPlanPro = company.momentPlan === 'Pro';
  return company;
};

export default useCompany;
