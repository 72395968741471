
const getObject = (key, defaultValue = {}) => {

    if (!key) {
        throw new Error('Local storage key is not defined: ' + key);
    }

    const value = localStorage.getItem(key);

    try {
      return value ? JSON.parse(value) : defaultValue;
    } catch (e) {
      console.error('Could not parse string', value);
      return defaultValue;
    }
};

const setObject = (key, value = {}) => {

    if (!key) {
        throw new Error('Local storage key is not defined: ' + key);
    }

    localStorage.setItem(key, JSON.stringify(value));
};

const LocalStorage = {
    getObject,
    setObject
};

export default LocalStorage;

