import { useMemo } from 'react';
import { Identity } from 'src/api';

// TODO won't work with borrowed accounts, think about migrating to impersonate?
// const { data: permissions } = useMakeQuery(MakeCb.get.all(cApi.userAccounts.$userAccountId.permissions,
//   { params: { userAccountId: Identity.userAccountId } }));
export const useAnyPermission = values => {
  const permissions = Identity.userAccount.permissions.permissions;

  return useMemo(() => {
    return permissions ? values.some(value => permissions.includes(value)) : false;
  }, [permissions]);
};

/**
 * A hook to check if provided permission is presented
 * within global permissions array from the Identity store
 *
 * @param {string} value - Permission to look for
 * @returns {boolean}
 */
const usePermission = value => useAnyPermission([value]);

export const useEveryPermission = values => {
  const permissions = Identity.userAccount.permissions.permissions;

  return useMemo(() => {
    return permissions ? values.every(value => permissions.includes(value)) : false;
  }, [permissions]);
};

export const useProjectPermission = (projectNumber, value) => {
  const permissions = Identity.userAccount.permissions.projectsPermissions[projectNumber];

  const hasGlobalPermission = useAnyPermission([value]);

  return permissions?.includes(value) || hasGlobalPermission;
};

export default usePermission;
