
const _ = {
    initialized: false
};

export function initializeIdentity(userAccountIdentity, securityData) {

    if (_.initialized) {
        throw Error('Identity initialized');
    }

    Object.assign(_, {
        userAccount: userAccountIdentity, // { id, fullName, iconId }
        userAccountId: userAccountIdentity.id,
        companyId: securityData.currentCompanyId,
        companyCode: securityData.currentCompanyCode,
        companyName: securityData.currentCompanyName,
        companyCountryCode: securityData.currentCompanyCountryCode,
        isImpersonating: securityData.isImpersonating,
        isMomentTeamUser: securityData.isMomentTeamUser,
        initialized: true
    });
}

class IdentityApi {

    get initialized() {
      return _.initialized;
    }

    get userAccountId() {
        this.validateGet();
        return _.userAccountId;
    }

    set userAccountId(_value) {
        this.validateSet();
    }

    get userAccount() {
        this.validateGet();
        return _.userAccount;
    }

    set userAccount(_value) {
        this.validateSet();
    }

    get companyCode() {
        this.validateGet();
        return _.companyCode;
    }

    set companyCode(_value) {
        this.validateSet();
    }

    get companyName() {
      this.validateGet();
      return _.companyName;
    }

    set companyName(_value) {
        this.validateSet();
    }

    get companyCountryCode() {
        this.validateGet();
        return _.companyCountryCode;
    }

    set companyCountryCode(_value) {
        this.validateSet();
    }

    get isImpersonating() {
      this.validateGet();
      return _.isImpersonating;
  }

    isMomentTeamUser() {
      return _.isMomentTeamUser;
    }

    set isImpersonating(_value) {
        this.validateSet();
    }

    validateGet() {
        if (!_.initialized) {
            throw new Error('Identity isn\'t initialized');
        }
    }

    validateSet() {
        throw new Error('Property is read-only');
    }
}


const Identity = new IdentityApi();


export default Identity;
