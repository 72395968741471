
export default function (navigateFn) {

  const state = {
    content: [],
    isLoading: true,
    navigate: false
  };

  const check = () => {

    if (!state.navigate) {
      return;
    }

    if (state.isLoading) {
      return;
    }

    if (state.content.length > 0) {
      navigateFn(state.content[0]);
    }

    state.navigate = false;
  };

  return {
    onData: (content = [], isLoading = true) => {
      state.content = content;
      state.isLoading = isLoading;
      check();
    },
    navigate: () => {
      state.navigate = true;
      check();
    }
  };

}
