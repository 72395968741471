import { useEffect } from 'react';
import { usePreviousWithDefault } from 'src/hooks';
import { useConstant } from 'src/hooks/useConstant';

/**
 * Calling the effectHook each time the dependency is changed passing it as a parameter inside.
 *
 * NOTE: effectHook won't ba called if dependency is changed to falsy, use `allowFalsy` to change this behaviour
 * @param {function} effectHook - the callback to be invoked with changed dependency
 * @param {array} dependencies - dependency list, should be used array of objects of the same type
 * @param {boolean} allowFalsy - set to true if you want to call effectHook when dependency is changed to falsy
 * @param {boolean} allowInitial - set to false if you want to disable initial useEffect call
 */
function useDependencyEffect(effectHook, dependencies, allowFalsy = false, allowInitial = true) {
  const initialDeps = useConstant(allowInitial ? [] : dependencies);
  const previousDeps = usePreviousWithDefault(dependencies, initialDeps);
  const changedDepIndex = dependencies.findIndex((dependency, index) => dependency !== previousDeps[index]);
  useEffect(() => {
    (dependencies[changedDepIndex] || allowFalsy) && effectHook(dependencies[changedDepIndex], changedDepIndex);
  }, dependencies);
}

export default useDependencyEffect;
