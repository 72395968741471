import { InfiniteData, Query, useQueryClient } from 'react-query';
import { BaseEntity, Content, Data, PagedData, QueryKeyStrict } from 'src/api/types';
import { Arrays } from 'src/helpers';
import { SortOrder } from 'src/api/localEnums';

const getQuery = (key: QueryKeyStrict, fieldsToIgnore: string[] = []) => {
  const queryIndex = key.findIndex(kPart => kPart.includes('query:'));
  if (queryIndex === -1) {
    return null;
  }
  const parsedQuery = JSON.parse(key[queryIndex].substring(6));
  fieldsToIgnore.forEach(field => delete parsedQuery[field]);

  return parsedQuery;
};

const isSameKey = (key: QueryKeyStrict, fieldsToIgnore: string[]) => (query: Query) => {
  const parsedQuery = getQuery(key, fieldsToIgnore);
  return query.queryKey.length === key.length && key.every(kPart => {
    if (kPart.includes('query:')) {
      const parsedQuery2 = getQuery(query.queryKey as QueryKeyStrict, fieldsToIgnore);
      return parsedQuery2 &&
        Object.keys(parsedQuery).length === Object.keys(parsedQuery2).length &&
        Object.keys(parsedQuery).every(qKey => parsedQuery[qKey] === parsedQuery2[qKey]);
    }
    return query.queryKey.includes(kPart);
  });
};

export const plainCompare = <T>(a: T, b: T): number => {
  if (typeof a === 'string' && typeof b === 'string') {
    return a.localeCompare(b, undefined, { caseFirst: 'false'/*string*/, numeric: true });
  }
  if (a === b) {
    return 0;
  } else if (a === null) {
    return 1;
  } else {
    return a > b ? 1 : -1;
  }
};

type SortRule = [string, SortOrder];

const prepareSortRules = (querySortRules: string[]): SortRule[] => {
  const sortRules: SortRule[] = [];
  querySortRules.forEach(rule => {
    const splittedRule = rule.split(',');
    let direction = splittedRule.pop() as SortOrder;
    const fields = [...splittedRule];
    if (!(direction in SortOrder)) {
      fields.push(direction);
      direction = SortOrder.asc;
    }
    fields.forEach(f => sortRules.push([f, direction]));
  });
  if (!sortRules.some(sr => sr[0] === 'id')) {
    sortRules.push(['id', SortOrder.desc]);
  }

  return sortRules;
};

const deepCompare = <T>(sortRules: SortRule[]) => (a: T, b: T): number => {
  const _sortRules = [...sortRules];
  const [field, direction = SortOrder.asc] = _sortRules[0];
  const aValue = a[field];
  const bValue = b[field];
  const compareResult = plainCompare<T>(aValue, bValue);
  if (compareResult === 0) {
    _sortRules.shift();
    return _sortRules.length === 0 ? 0 : deepCompare<T>(_sortRules)(a, b);
  }
  const order = { asc: [1, -1], desc: [-1, 1] };
  return order[direction][compareResult === 1 ? 0 : 1];
};

type ResortExistingData = {
  page?: number,
  pageParams?: unknown[],
  size?: number,
  totalElements?: number
}

const getFirstData = <E extends BaseEntity>(data: InfiniteData<PagedData<E>> | Data<E>): PagedData<E> => {
  return 'pages' in data ? (data as InfiniteData<PagedData<E>>).pages[0] : (data as PagedData<E>);
};

export const useResortExistingData = <E extends BaseEntity>(key: QueryKeyStrict): [Content<E>, ResortExistingData] => {
  const queryClient = useQueryClient();

  if (!key.some(kPart => kPart.includes('"sort":'))) {
    return [null, {}];
  }

  if (queryClient.getQueryData(key, { stale: false })) { // exact same key present in cache
    return [null, {}];
  }

  const parsedQuery = JSON.parse(key.find(kPart => kPart.includes('query:')).substring(6));
  const sortRules = prepareSortRules(Arrays.ensureArray(parsedQuery.sort));
  const sortKeys = sortRules.map(sr => sr[0]);

  const queriesData = queryClient.getQueriesData({
    queryKey: key[0],
    exact: false,
    stale: false,
    predicate: isSameKey(key, ['sort', 'page'])
  }) as [QueryKeyStrict, InfiniteData<PagedData<E>> | PagedData<E>][];

  const _existingDataPages = queriesData
    .filter(([, data]) => !!data)
    .filter(([key]) => !key[0].endsWith('/count'))
    .filter(([, data]) => {
      const firstContent = getFirstData(data).content[0];
      return !(firstContent && !sortKeys.every(sk => sk in firstContent));
    })
    // sort by data freshness
    .map(pair => [pair[0], pair[1], queryClient.getQueryState(pair[0]).dataUpdatedAt])
    .sort((a, b) => a[2] < b[2] ? 1 : -1);

  const existingDataPages = (_existingDataPages as [QueryKeyStrict, InfiniteData<PagedData<E>> | PagedData<E>, number][]);

  if (existingDataPages.length === 0) {
    return [null, {}];
  }

  const firstData = existingDataPages[0][1];
  const totalElements = getFirstData(firstData).page.totalElements;

  const content = (() => {
    const grouped = {};
    for (const [key1, data] of existingDataPages) {
      const groupKey = JSON.stringify(getQuery(key1, ['page']));
      grouped[groupKey] ||= [];
      const dataList = 'pages' in data ? data.pages : [data];
      for (const _data of dataList) {
        grouped[groupKey].push(..._data.content);
        if (grouped[groupKey].length === totalElements) {
          return grouped[groupKey];
        }
      }
    }
  })();

  if (!content?.length) {
    return [null, {}];
  }

  const sortedContent = content.sort(deepCompare(sortRules));

  if ('pages' in firstData) { // infinite request
    return [sortedContent, {
      pageParams: firstData.pageParams,
      size: firstData.pages[0]?.page.size,
      totalElements
    }];
  } else {
    return [sortedContent, {
      page: parsedQuery.page,
      size: parsedQuery.size,
      totalElements
    }];
  }
};

export const useInitialQueryData = <E extends BaseEntity>(key: QueryKeyStrict): Data<E> => {
  const [sortedContent, { page, size, totalElements }] = useResortExistingData<E>(key);

  if (!sortedContent) {
    return;
  }

  const pageOffset = page * size;
  const contentForCurrentPage = sortedContent.length > size ? sortedContent.slice(pageOffset, pageOffset + size) : sortedContent;

  return {
    // links and metadata are not supported
    page: { number: page, totalElements, totalPages: +(totalElements / size).toFixed(), size: size },
    content: contentForCurrentPage
  };
};

export const useInitialInfiniteQueryData = <E extends BaseEntity>(key: QueryKeyStrict): InfiniteData<PagedData<E>> => {
  const [sortedContent, { pageParams, size, totalElements }] = useResortExistingData<E>(key);

  if (!sortedContent) {
    return;
  }

  const totalPages = +(totalElements / size).toFixed();
  return {
    pages: [...Array(totalPages)].map((_, page): PagedData<E> => {
      const pageOffset = page * size;
      return {
        page: { number: page, totalElements, totalPages, size: size },
        content: sortedContent.slice(pageOffset, pageOffset + size)
      };
    }),
    pageParams
  };
};
