import i18next from 'i18next';
import React, { Suspense } from 'react';
import DefaultQueryClientProvider from './queryClient';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Router } from 'react-router-dom';
import 'regenerator-runtime/runtime'; // For ES2017 await
import { Api } from 'src/api';
import Path from 'src/helpers/Path';
import history from 'src/history';
import { createRoot } from 'react-dom/client';
import { defaultLanguage, dayjsLocalLibraries, supportedLanguages } from 'src/i18n/languages';
import { Layout, Public, SignUp } from 'src/pages';
import topRoutesList from 'src/routing/routes/topRoutesList';
import {redirectToLogin, redirectToSignup, redirectToSuccessCreateCompany} from 'src/routing/RoutingUtils';
import './styles/index.js';


const redirectRules = {
  ['/public/links/registerExpense']: '/myself/claims'
};

const isDevelopment = CONFIG.environment === 'development';

const url = new URL(CONFIG.apiUrl, document.location);

const makeRequest = (path, params = {}) => (
  fetch(Path.join(url.pathname, Path.applyParams(path.toString(), params)))
    .then(r => new Promise((resolve, reject) => r.ok && r.json ? resolve(r.json()) : reject(r)))
);

const initI18next = async (locale, publicI18n) => {
  const localesToLoad = [defaultLanguage];
  if (locale !== defaultLanguage) {
    localesToLoad.push(locale);
  }
  let translations;
  if (publicI18n) {
    translations = await Promise.all(localesToLoad.map(locale => import(`src/i18n/locales/public_${supportedLanguages[locale]}.json`)));
  } else {
    translations = await Promise.all(localesToLoad.map(locale => import(`src/i18n/locales/client_${supportedLanguages[locale]}.json`)));
  }

  const resources = localesToLoad.reduce((memo, locale, i) => {
    memo[locale] = { translation: translations[i].default };
    return memo;
  }, {});
  return i18next.init({
    compatibilityJSON: 'v3',
    fallbackLng: defaultLanguage,
    initImmediate: false,
    returnEmptyString: false,
    lng: locale,
    debug: isDevelopment,
    resources,
    interpolation: {
      escapeValue: false,
      prefix: '{',
      suffix: '}'
    }
  });
};

const catchRequestError = (ns) => async (e) => {
  if ([401, 403].includes(e.status)) {
    if (ns === 'users/me') {
      const initialOptions = await makeRequest(Api.signup.initialOptions).catch(err => console.log(err));
      const noCompanyUser = initialOptions?.roles?.some(item => item === 'ROLE_USER_WITHOUT_A_COMPANY') || false;
      if (noCompanyUser) {
        redirectToSignup();
        return;
      }
      if (initialOptions?.roles?.some(item => item === 'ROLE_USER_WITHOUT_VERIFICATION')) {
        redirectToSuccessCreateCompany();
        return;
      }
    }
    redirectToLogin('/');
    return;
  }
  document.querySelector('#loading').classList.add('show-initial-error');
  e.ns = ns;
  throw e;
};

const redirect = (pathname) => {
  const url = new URL(window.location);
  url.pathname = pathname;
  window.history.replaceState(null, null, url);
};

const container = document.querySelector('#app');
const root = createRoot(container);

(async () => {
  const redirectToNewPathname = Object.keys(redirectRules).find(originalPathname => window.location.pathname === originalPathname);
  if (redirectToNewPathname) {
    redirect(redirectRules[redirectToNewPathname]);
  }

  const pathnameParts = window.location.pathname.split('/');
  let companyCode = pathnameParts[1];

  if (companyCode === 'public') {
    await initI18next(defaultLanguage, true);

    root.render((
      <Router history={history}>
        <Suspense fallback={null}>
          <DefaultQueryClientProvider>
            <Public />
          </DefaultQueryClientProvider>
        </Suspense>
      </Router>
    ));
    const loading = document.getElementById('loading');
    loading.parentNode.removeChild(loading);
    throw new Error('public');
  }

  if (companyCode === 'selfsignup') {
    const initialOptions = await makeRequest(Api.signup.initialOptions).catch(err => console.log(err));
    await initI18next(defaultLanguage, true);
    root.render((
      <Router history={history}>
        <Suspense fallback={null}>
          <DefaultQueryClientProvider>
            <SignUp initialOptions={initialOptions} />
            <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
          </DefaultQueryClientProvider>
        </Suspense>
      </Router>
    ));
    const loading = document.getElementById('loading');
    loading.parentNode.removeChild(loading);
    throw new Error('signup');
  }

  if (!companyCode || companyCode in topRoutesList) {
    // if root or no company code, but uri seems to be valid —
    // checking for logged-in state through systemUser request, alongside getting default companyCode
    const systemUser = await makeRequest(Api.users.me)
      .catch(catchRequestError('users/me'));

    companyCode = (systemUser.userAccounts.find(({ company }) => company.id === systemUser.mainCompanyId) || systemUser.userAccounts[0]).company.code;

    redirect('/' + companyCode + window.location.pathname);
  }
  // for most reloads/ direct navigation checking for logged-in state with initialOptions request
  const initialOptions = await makeRequest(Api.companies.$company.initialOptions, { company: companyCode })
    .catch(catchRequestError('initialOptions'));

  const locale = initialOptions.globalJson.locale;
  window.tibetVersion = initialOptions.globalJson.version;
  await initI18next(locale);

  if (locale in dayjsLocalLibraries) {
    await import(`dayjs/locale/${dayjsLocalLibraries[locale]}.js`);
  }

  return Promise.resolve(initialOptions);
})().then((initialOptions) => {

  root.render((
    <Router history={history}>
      <Suspense fallback={null}>
        <DefaultQueryClientProvider>
          <Layout initialOptions={initialOptions} />
          <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
        </DefaultQueryClientProvider>
      </Suspense>
    </Router>
  ));
}).catch((e) => {
  if (!['public', 'signup'].includes(e.message)) {
    console.error('Unhandled error response from', e.ns, e.message, e.statusCode);
  }
});
