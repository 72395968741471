// lightweight list of top routes to use in routing to differentiate from companyCode

export default {
  myself: 'myself',
  tasks: 'tasks',
  customers: 'customers',
  offers: 'offers',
  projects: 'projects',
  users: 'users',
  quality: 'quality',
  economy: 'economy',
  company: 'company',
  settings: 'settings',
  analytics: 'analytics',
  playground: 'playground'
};
