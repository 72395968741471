
import { useEffect } from 'react';


function useOnUnmount(onUnmount) {
  useEffect(() => onUnmount, []);
}


export default useOnUnmount;
