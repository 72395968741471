
import { buildEnums } from 'src/api/utils';
import { t } from 'src/i18n/i18next';

// Underscore used to separate enum values from additional

const isPublicKey = (key) => key.indexOf('_') !== 0;

/**
 *
 * @param {object} enumObject
 * @param {object} [firstOption]
 * @returns {{label: *, title: *, value: *}[]}
 */
const toOptions = (enumObject, firstOption) => {

  const options = Object
    .keys(enumObject)
    .filter(isPublicKey)
    .map(key => ({
      value: key,
      label: t(enumObject._t + '.' + key),
      title: t(enumObject._t + '.' + key),
  }));

  if (firstOption) {
    options.unshift(firstOption);
  }

  return options;
};

const values = (enumObject) => Object.keys(enumObject).filter(isPublicKey);

const title = (enumObject) => {
  return t(enumObject._t + '._title');
};

title.short = (enumObject) => {
  return t(enumObject._t + '._shortTitle');
};

const options = {
  all: {
    get label() {
      return t('global.boolean.all'); // test should be loaded dynamically
    },
    value: 'all'
  }
};

const enumT = (enumObject, value) => {
  if (!value) {
    return null;
  }
  if (value === 'none') {
    return t([enumObject._t + '.' + value, 'global.words.None']);
  }
  return t(enumObject._t + '.' + value);
};

enumT.hideIf = (enumObject, value, valueToHide) => {

  if (value === valueToHide) {
    return null;
  }

  return enumT(enumObject, value);
};

const build = (enumObjects) => {
  buildEnums(enumObjects);
};

const Enum = {
  options,
  values,
  toOptions,
  title,
  t: enumT,
  build,
  isPublicKey
};

export default Enum;
