import { cApi, Identity } from 'src/api';
import Path from 'src/helpers/Path';
import history from 'src/history';

// during changing of react routes window.location !== history.location (window.location outdated)
const getCurrentUrl = () => {
  const url = new URL(history.location.pathname, window.location);
  url.search = history.location.search;
  return url;
};

const getCurrentQuery = () => {
  const url = getCurrentUrl();
  return Object.fromEntries(url.searchParams);
};

const updateQuery = (url, query) => {
  url.search = '';
  Object.keys(query).forEach(key => url.searchParams.set(key, query[key]));
};

const setCurrentQuery = (query = {}) => {
  if (document.body.classList.contains('show-backbone') && Object.keys(query).length) {
    return;
  }
  const newUrl = getCurrentUrl();
  updateQuery(newUrl, query);
  window.history.replaceState({}, document.title, newUrl.toString());
};

const generateQuery = (pathname, query, params) => {
  if (pathname.indexOf(cApi._) === 0 && !params.company) { // checking first to make safe get company code
    params.company = Identity.companyCode;
  }
  pathname = Path.applyParams(pathname, params);

  const url = new URL('/api/1.0', document.location);
  url.pathname = Path.join(url.pathname, pathname);
  Url.updateQuery(url, query);
  return url;
};

const Url = {
  updateQuery,
  generateQuery
};

Object.defineProperty(Url, 'currentQuery', {
  get: getCurrentQuery,
  set: setCurrentQuery
});

export default Url;
