import { QueryClient, useQueryClient } from 'react-query';
import { Api, cApi } from './Api';
import { Id, QueryKey } from './types';
import Arrays from 'src/helpers/Arrays';

/**
 * relatedQueriesMap is a Map of sourceRequest: [targetRequests]
 * where `sourceRequest` is the mutation you perform, and the `targetRequests`
 * is the list of queries you want to be refetched on that mutation.
 *
 * Note:
 *    when adding new key - make sure there is no one already, as bottom key will rewrite upper one.
 *    To ensure that - add new keys alphabetically.
 *
 * Note: query is not refetched until you visit page where it is used
 *
 * E.g.: on mutating cApi.timeRecords I want to update cApi.projectExtensions queries as well,
 * as they might be updated because of cApi.timeRecords mutation
 */
// TODO single level drop + functions/function
const _relatedRequestsMap: { [x: string]: (string | object)[] } = {
  [Api.users.me.lastReadApplicationNewsAt.toString()]: [
    cApi.global.newsEntries.unseenId
  ],
  [cApi.absenceAdjustments.toString()]: [
    cApi.absences.statisticsForUser
  ],
  [cApi.absences.toString()]: [
    cApi.employeesEducationPoolDeductibles.statisticsForUser
  ],
  [cApi.absences.batchAddAbsences.toString()]: [
    cApi.absences
  ],
  [cApi.additionalCustomer.toString()]: [
    cApi.invoicingPreferences.additionalCustomer
  ],
  [cApi.addresses.functions.updateCoordinates.toString()]: [
    cApi.addresses,
    cApi.projects,
    cApi.userAccounts
  ],
  [cApi.authenticationTokenUserAccounts.functions.deleteKey.toString().toString()]: [cApi.authenticationTokenUserAccounts],
  [cApi.authenticationTokenUserAccounts.functions.generateKey.toString()]: [cApi.authenticationTokenUserAccounts],
  [cApi.authenticationTokenUserAccounts.functions.updateExpiryDate.toString()]: [cApi.authenticationTokenUserAccounts],
  [cApi.calendarImport.deleteIntegration.toString()]: [cApi.calendarImport.get],
  [cApi.calendarImportTimeRecords.toString()]: [
    cApi.userAccounts.me.toApproveInfo
  ],
  [cApi.calendarImportTimeRecords.functions.createTimeRecords.toString()]: [
    cApi.calendarImportTimeRecords,
    cApi.userAccounts.me.toApproveInfo
  ],
  [cApi.contactInCustomers.functions.assignByCustomerIdAndContactId.toString()]: [cApi.contacts, cApi.contactInCustomers],
  [cApi.contactInCustomers.functions.removeByCustomerIdAndContactId.toString()]: [cApi.contacts, cApi.contactInCustomers],
  [cApi.contactInProjects.functions.assignByProjectIdAndContactId.toString()]: [cApi.contacts, cApi.contactInProjects],
  [cApi.contactInProjects.functions.removeByProjectIdAndContactId.toString()]: [cApi.contacts, cApi.contactInProjects],
  [cApi.contacts.mergeContacts.toString()]: [cApi.contacts],
  [cApi.employeesEducationAdjustments.toString()]: [
    cApi.employeesEducationPoolDeductibles.statisticsForUser,
    cApi.timeRecords.educationFundAmountAccrueStatisticsOverview
  ],
  [cApi.employeesEducationAdjustments.transferAndSetInitialEducationDeductibleAdjustments.toString()]: [
    cApi.timeRecords.educationFundAmountAccrueStatisticsOverview
  ],
  [cApi.files.functions.uploadFileToSharePoint.toString()]: [
    cApi.files
  ],
  [cApi.functions.changeMomentPlan.toString()]: [
    cApi,
    cApi.momentPlan,
    cApi.employeesEducationPoolDeductibles.statisticsForUser
  ],
  [cApi.holidays.toString()]: [
    cApi.holidays.search.findByDate
  ],
  [cApi.integrations.toString()]: [
    cApi.userAccounts
  ],
  [cApi.milestones.toString()]: [
    cApi.projects.gantt
  ],
  [cApi.notifications.toString()]: [
    cApi.userAccounts.me.toApproveInfo
  ],
  [cApi.notifications.functions.readAll.toString()]: [
    cApi.notifications
  ],
  [cApi.preferences.toString()]: [
    cApi.calendarImport.get
  ],
  [cApi.priceDefinitions.withHourlyRates.toString()]: [
    cApi.priceDefinitions
  ],
  [cApi.projectExtensions.functions.moveToProject.toString()]: [
    cApi.projectExtensions
  ],
  [cApi.projectParticipantCompanyContacts.toString()]: [
    cApi.projectParticipantCompanies
  ],
  [cApi.projectParticipantCompanies.$id.inviteForReview.toString()]: [
    cApi.projectParticipantCompanies
  ],
  [cApi.projectResourceReservations.toString()]: [
    cApi.projectResourceReservations.latestWithColor,
    cApi.projectResourceReservations.forecastSum
  ],
  [cApi.projectResourceReservations.batchUpdateReservations.toString()]: [
    cApi.projectResourceReservations.latestWithColor,
    cApi.projectResourceReservations.forecastSum
  ],
  [cApi.projects.toString()]: [ // TODO cApi.projects.$id
    cApi.projects.$id.competency,
    cApi.projects.$id.overview
  ],
  [cApi.projects.importFromOtherProject.toString()]: [
    cApi.projectExtensions
  ],
  [cApi.scheduledTaskSettings.functions.run.toString()]: [
    cApi.scheduledTaskSettings
  ],
  [cApi.timeRecords.toString()]: [
    cApi.projectExtensions,
    cApi.employeesEducationPoolDeductibles.statisticsForUser
  ],
  [cApi.userAccounts.toString()]: [
    cApi.projects.$id.competency,
    cApi.hierarchy
  ],
  [cApi.timeRecordAdjustments.recreate.toString()]: [cApi.timeRecords.hoursSaldoStatistics],
  [cApi.overtimeAdjustments.recreate.toString()]: [cApi.timeRecords.hoursSaldoStatistics]

};

const relatedRequestsMap = Object.keys(_relatedRequestsMap).reduce((memo: { [x: string]: string[] }, refetchSource: string) => {
  memo[refetchSource] = _relatedRequestsMap[refetchSource].map(refetchTarget => refetchTarget.toString());
  return memo;
}, {});

const getRelatedRequests = (key: string) => {
  const requests: QueryKey[] = [];
  const addCommonRequests = (key: string) => {
    requests.push(key + '/overview');
    requests.push(key + '/getReport');
    requests.push(key + '/count');
  };

  const relatedQueries = relatedRequestsMap[key];
  if (relatedQueries?.length) {
    requests.push(...relatedQueries);
    relatedQueries.forEach(addCommonRequests);
  }
  addCommonRequests(key);
  return requests;
};

/**
 * notesClassesMap is a map for updating changeLogs of Class when Class is updated.
 *
 * E.g. when project name is updated (cApi.projects mutation), all changeLogs with entityClass: 'Project' are refetched
 */
const notesClassesMap = {
  [cApi.customers.toString()]: 'Customer',
  [cApi.projectRevisions.toString()]: 'ProjectRevision',
  [cApi.projects.toString()]: 'Project',
  [cApi.files.toString()]: 'CloudFile',
  [cApi.preferences.toString()]: 'CompanyPreferenceKeyValue',
// InvoicingPreferences: cApi.InvoicingPreferences,
  [cApi.projectExtensions.toString()]: 'ProjectExtension',
  [cApi.risks.toString()]: 'Risk',
  [cApi.invoiceLines.toString()]: 'InvoiceLine',
// SystemUser: cApi.SystemUser,
  [cApi.priceDefinitions.toString()]: 'PriceDefinition',
  [cApi.checklistItems.toString()]: 'ChecklistItem',
  [cApi.offers.toString()]: 'Offer',
  [cApi.claims.toString()]: 'ClaimEntity', // ?
  [cApi.moneyTransactions.toString()]: 'MoneyTransaction',
  [cApi.checklists.toString()]: 'Checklist',
  [cApi.nonconformities.toString()]: 'Nonconformity',
  [cApi.routines.toString()]: 'Routine',
  [cApi.invoices.toString()]: 'Invoice',
  [cApi.tasks.toString()]: 'Task',
  [cApi.projectMemberships.toString()]: 'ProjectMembership',
  [cApi.products.toString()]: 'Product',
  [cApi.toString()]: 'Company',
  [cApi.userAccounts.toString()]: 'UserAccount',
  [cApi.integrations.toString()]: 'Integration',
  [cApi.contacts.toString()]: 'Contact'
};

const getRelatedNotesQueries = (queryClient: QueryClient, key: string, id: Id): QueryKey[] => {
  if (!id || !notesClassesMap[key]) {
    return null;
  }
  return queryClient.getQueriesData(cApi.notes.toString())
    .map(([queryKey]: [QueryKey, unknown]) => Arrays.ensureArray(queryKey))
    .filter(queryKey => queryKey.some(keyPart => keyPart.includes(`"entityClass":"${notesClassesMap[key]}"`)))
    .filter(queryKey => queryKey.some(keyPart => keyPart.includes(`"entityId":${id}`)));
};

const useRelatedQueries = () => {
  const queryClient = useQueryClient();

  return (key: string, id: Id) => {
    const result = getRelatedRequests(key);

    const notesQueryKeys = getRelatedNotesQueries(queryClient, key, id);
    if (notesQueryKeys) {
      result.push(...notesQueryKeys);
    }

    return result;
  };
};

export default useRelatedQueries;
