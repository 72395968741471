import { createBrowserHistory } from 'history';

const history = createBrowserHistory();

window.reactHistory = history;

let prevLocationPathname = '';
history.listen(newLocation => {
  const app = document.getElementById('app');
  if (app && prevLocationPathname !== newLocation.pathname) {
    prevLocationPathname = newLocation.pathname;
    app.scrollTop = 0;
  }
});

export function goTo(path) {
  return history.push(path);
}

export default history;


