import lazyLoader from '../lazyLoader';
import { t, i18next } from './i18next';
export const defaultLanguage = 'en';
import merge from 'lodash/merge';

const otherLanguages = {
  da: 'da',
  is: 'is',
  nb: 'nb',
  sv: 'sv'
};

export const supportedLanguages = {
  ...otherLanguages,
  en: 'en'
};

const supportedLanguagesLegacy = {
  ...supportedLanguages,
  nb: 'no'
};

export const dayjsLocalLibraries = {
  ...otherLanguages,
  en: 'en-gb'
};

export const timeAgoLanguages = Object.values(supportedLanguagesLegacy);

const antLocalParts = {
  da: 'da_DK',
  en: 'en_GB',
  is: 'is_IS',
  nb: 'nb_NO',
  sv: 'sv_SE'
};

export const locales = {  // temporary fallback solution
  ...antLocalParts,
  en: 'en_US'
};

export const antLocaleLibrary = async (locale) => {
  const localeCode = antLocalParts[locale];
  const defaultLocales = (await lazyLoader(import(`antd/es/locale/${antLocalParts[defaultLanguage]}.js`))).default;
  const locales = merge({}, defaultLocales, (await lazyLoader(import(`antd/es/locale/${localeCode}.js`))).default);
  locales.Popconfirm = {
    okText: t('global.boolean.yes'),
    cancelText: t('global.boolean.no')
  };
  locales.Form.defaultValidateMessages.required = t('global.exception.required');
  return locales;
};

const analyticsLanguages = {
  en: 'English',
  nb: 'Norsk',
  da: 'Dansk',
  is: 'Islenska',
  sv: 'Svenska',
  fi: 'Suomi',
  de: 'Deutsch',
  fr: 'Francais'
};

export const analyticsLanguage = () => {

  let lang = i18next.language;
  if (lang === 'no') {
    lang = 'nb';
  }

  const al = analyticsLanguages[lang];
  return al || analyticsLanguages.en;
};
