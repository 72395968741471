
const Format = {};

/**
 * Format an account number for display
 * @param {number} account Account number
 * @return {string} Display formatted account number
 */
Format.bankAccountNumber = account => {
  if ((typeof account) !== 'object' || (typeof account.toString) !== 'function') {
    return account;
  }

  const str = account.toString();
  if (str.length === 11) {
    return `${str.substring(0, 4)}.${str.substring(4, 2)}.${str.substring(6)}`;
  }

  return account;
};

Format.joinNotBlank = (items = [], separator) => {
  return items.filter(v => !!v && v.trim() !== '').join(separator);
};

Format.formatAddressLine = (address) => {

  const { cadastreId, addressLine1, addressLine2, addressLine3, postalCode, postalPlace  } = address || {};

  const parts = [
    cadastreId,
    addressLine1,
    addressLine2,
    addressLine3,
    Format.joinNotBlank([postalCode, postalPlace], ' ')
  ];

  return Format.joinNotBlank(parts, ', ');
};


Format.formatOverflowString = (string = '', length, overflow = '...') => {
  return string && length && string.length > length ? string.substr(0, length - overflow.length) + overflow : string;
};

Format.formatStringToBoolean = (value, defaultValue = null) => {
  if (typeof defaultValue === 'boolean' && (value === undefined || value === null)) {
    return defaultValue;
  }
  return value === 'false'
    ? false : value === 'true'
      ? true : !!value;
};

Format.reverseFormatNumber = (val, locale, silent = false) => {
  val = val.replace('−', '-'); // Intl.NumberFormat pushes 8722 character, while it is invalid as a part of the number, replacing with 45
  const parts = new Intl.NumberFormat(locale).formatToParts(1111.1);
  const group = parts.find(part => part.type === 'group').value;
  const decimal = parts.find(part => part.type === 'decimal').value;
  const reversedVal = val
    .replace(new RegExp('\\' + group, 'g'), '')
    .replace(new RegExp('\\' + decimal, 'g'), '.')
    .replace(/[^\d.-]/g, '');
  if (Number.isNaN(+reversedVal) && !silent) {
    console.error(`Failed parsing number: "${val}"`);
  }
  return +reversedVal;
};

Format.formatNumber = (number) => new Intl.NumberFormat('nb').format(number);
Format.formatDecimal = (number, decimal = 2) => new Intl.NumberFormat('nb', { minimumFractionDigits: decimal, maximumFractionDigits: decimal, style: 'decimal' }).format(number);

Format.formatMoney = (value, currency = undefined, decimals = 0, notation, allowZero = false) => {
  if (!value && !allowZero) {
    return '';
  }
  return new Intl.NumberFormat('nb-NO', {
    style: currency ? 'currency' : 'decimal',
    currency, currencyDisplay: 'code',
    notation: notation,
    maximumFractionDigits: decimals
  }).format(value);
};

Format.formatUserNameToInitials = (fullName, firstName, lastName) => {
  const name = fullName || (firstName && (lastName ? `${firstName} ${lastName}` : firstName)) || '';

  if (!name) {
    return '';
  }

  const nameParts = name.split(' ');
  const firstNameInitial = nameParts[0].charAt(0);

  if (nameParts.length > 1) {
    const lastNameInitial = nameParts.pop().charAt(0);
    return `${firstNameInitial}${lastNameInitial}`;
  }

  return firstNameInitial;
};

export default Format;
