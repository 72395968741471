

import { useRef } from 'react';


function useSingleton(singletonCreator) {

  const singletonRef = useRef(null);

  if (!singletonRef.current) {
    singletonRef.current = singletonCreator();
  }

  if (!singletonRef.current) {
    throw Error('Singleton wasn\'t initialized!');
  }

  return singletonRef.current;
}


export default useSingleton;
