
import { useEffect } from 'react';


function useOnMount(onMount) {
  if (onMount[Symbol.toStringTag] === 'AsyncFunction') {
    const _onMount = onMount;
    onMount = () => {
      _onMount();
    };
  }
  useEffect(onMount, []);
}


export default useOnMount;
