import Identity from 'src/api/Identity';
import { goTo } from 'src/history';
import onEnter from './Navigate.onEnter';
import Path from './Path';


const link = {
  toPage: (page = '') => Path.join('/' + Identity.companyCode, page),
  toPageWithoutCompanyCode: (page = '') => Path.join(page),
  toHome: () => '/' + Identity.companyCode,
  toOffer: (offerNumber) => link.toPage('offers/entries/' + offerNumber),
  toChecklistTemplates: () => link.toPage('quality/checklists'),
  toCompany: (companyCode) => '/' + companyCode,
  toCustomer: (customerNumber) => link.toPage('customers/entries/' + customerNumber),
  toClaim: (claimId) => link.toPage('myself/claims/entries/' + claimId),
  toTenant: (qlikId) => link.toPage('analytics/tenants/entries/' + qlikId),
  toProject: (projectNumber) => link.toPage('projects/entries/' + projectNumber),
  toUser: (userAccountId) => link.toPage('users/entries/' + userAccountId),
  toContact: (contactId) => link.toPage('customers/contacts/entries/' + contactId),
  toInvoice: (invoiceId) => link.toPage('economy/invoices/' + invoiceId),
  toTask: (taskNumber) => link.toPage('tasks/entries/' + taskNumber),
  toNonconformityTemplate: (id) => link.toPage('quality/nonconformitiesTemplates/entries/' + id),
  toMyRoutines: (id) => link.toPage('myself/routines?routineId=' + id),
  toMyAbsencePlanning: () => link.toPage('myself/hours/plannedAbsences'),
  toSignup: () => '/selfsignup/createCompany',
  toSuccessCreateCompany: () => '/selfsignup/successCreatedCompany',
  toLogin: redirect => '/login' + (redirect ? '?redirect=' + redirect : ''),
  toLogout: () => '/logout',
  toDepartments: (id) => '/settings/departments/' + id,
  toLicenses: () => '/settings/preferences/licenses',
  toGoogleMap: ({ latitude, longitude }) => `//maps.google.com/?q=${latitude},${longitude}`,
  toGoogleMapImage: ({ width, height, latitude, longitude, scale = 2, zoom = 15 }) => {
    const urlParams = new URLSearchParams({
      size: width + 'x' + height,
      scale,
      zoom,
      markers: `color:blue|${latitude},${longitude}`,
      key: 'AIzaSyBAFKAYG9dHXDMl7vH-amOq2MRYO9SIlgY'
    });
    return '//maps.googleapis.com/maps/api/staticmap?' + urlParams;
  },
  toPayments: (query) => link.toPage('economy/payments' + (query ?? '')),
  toProjectHours: (projectNumber) => link.toPage('projects/entries/' + projectNumber + '/hours'),
  toProjectStatistics: (projectNumber) => link.toPage('projects/entries/' + projectNumber + '/insight/statistics'),
  toProjectEconomy: (projectNumber) => link.toPage('projects/entries/' + projectNumber + '/insight/economy'),
  toRoutine: routineId => link.toPage('quality/routines?routineId=' + routineId),
  toMomentPlan: () => link.toPage('/settings/plan'),
  toAccessControl: (accessControlId) => link.toPage('/settings/accessControl/entries/' + accessControlId),
  toNewAccessControl: (accessRoleType) => link.toPage('/settings/accessControl/' + accessRoleType + '/new'),
  toAccessControlOverview: () => link.toPage('/settings/accessControl'),
  toSubscriptions: (priceDefinitionProjectProjectNumber, subscriptionId) =>
    link.toPage('projects/entries/' + priceDefinitionProjectProjectNumber + '/setup/price/' + subscriptionId)
};
link.toNonconformity = (projectNumber, id) => `${link.toProject(projectNumber)}/quality/nonconformities/entries/${id}`;
link.toChecklists = (projectNumber) => `${link.toProject(projectNumber)}/quality/checklists`;
link.toChecklist = (projectNumber, id) => `${link.toChecklists(projectNumber)}/entries/${id}`;
link.toChecklistTemplate = (id) => `${link.toChecklistTemplates()}/entries/${id}`;
link.toReviews = (projectNumber) => `${link.toProject(projectNumber)}/quality/reviews`;
link.toMapPlans = projectNumber => `${link.toProject(projectNumber)}/maps/mapWithPlans`;
link.toMapProducts = projectNumber => `${link.toProject(projectNumber)}/maps/mapProducts`;
link.toProjectFiles = (projectNumber) => `${link.toProject(projectNumber)}/files`;
link.toProjectPlan = (projectNumber) => `${link.toProject(projectNumber)}/resources`;

const Navigate = {
  onEnter,
  link,
  toHome: () => goTo(Navigate.link.toHome()),
  toPage: (page) => goTo(Navigate.link.toPage(page)),
  toPageWithoutCompanyCode: (page) => goTo(Navigate.link.toPageWithoutCompanyCode(page)),
  toAbsolutePage: page => goTo(page),
  toCustomer: (customerNumber) => goTo(Navigate.link.toCustomer(customerNumber)),
  toProject: (projectNumber) => goTo(Navigate.link.toProject(projectNumber)),
  toProjectActivites: (projectNumber) => goTo(Navigate.link.toProject(projectNumber) + '/extensions'),
  toProjectFiles: (projectNumber) => goTo(Navigate.link.toProjectFiles(projectNumber)),
  toTenant: (qlikId) => goTo(Navigate.link.toTenant(qlikId)),
  toOffer: (offerNumber) => goTo(Navigate.link.toOffer(offerNumber)),
  toInvoice: (invoiceId) => goTo(Navigate.link.toInvoice(invoiceId)),
  toUser: (userAccountId) => goTo(Navigate.link.toUser(userAccountId)),
  toContact: (customerId) => goTo(Navigate.link.toContact(customerId)),
  toTask: (taskNumber) => goTo(Navigate.link.toTask(taskNumber)),
  toPayments: (query) => goTo(Navigate.link.toPayments(query)),
  toProjectHours: (projectNumber) => goTo(Navigate.link.toProjectHours(projectNumber)),
  toProjectStatistics: (projectNumber) => goTo(Navigate.link.toProjectStatistics(projectNumber)),
  toAccessControl: (accessControlId) => goTo(Navigate.link.toAccessControl(accessControlId)),
  toAccessControlOverview: () => goTo(Navigate.link.toAccessControlOverview()),
  toProjectEconomy: (projectNumber) => goTo(Navigate.link.toProjectEconomy(projectNumber))
};

/* Examples:

Navigate.link.toCustomer('1') === '/companyCode/customers/entries/1'
Navigate.toCustomer('1') -> will navigate to url '/companyCode/customers/entries/1'

*/

export default Navigate;
