import { useMemo } from 'react';
import { cApi, Enums, Identity, MakeCb } from 'src/api';
import { useMakeQuery } from 'src/hooks/useMakeQuery';

/**
 * Getting preferences hierarchically from provided scope and up
 * @param {Enums.Scope} [scope]
 * @returns {object}
 */
export const usePreferences = (scope = Enums.Scope.user) => {

  if (scope === Enums.Scope.subCompany) {
    throw 'subCompany is not supported';
  }

  const userAccountId = scope === Enums.Scope.user ? Identity.userAccountId : null;

  const config = { keepPreviousData: true, refetchOnMount: false, staleTime: 60 * 60 * 1000 }; // 1 hour

  const { data: globalPreferences } = useMakeQuery(MakeCb.get.one(cApi.preferences, Enums.Scope.global),
      { ...config, staleTime: 24 * 60 * 60 * 1000 }); // 1 day
  const { data: companyPreferences } = useMakeQuery(MakeCb.get.one(cApi.preferences, Enums.Scope.company), { ...config, enabled: scope !== Enums.Scope.global });

  const { data: userPreferences } = useMakeQuery(MakeCb.get.one(cApi.preferences, Enums.Scope.user, { query: { userAccountId } }),
    { ...config, enabled: !!userAccountId });

  return useMemo(() => {
    if (scope === Enums.Scope.user && !userPreferences) {
      return {};
    }
    if (!companyPreferences || !globalPreferences) {
      return {};
    }

    return Object.assign({}, globalPreferences, companyPreferences, (userAccountId ? userPreferences : {}));
  }, [globalPreferences, companyPreferences, userPreferences]);
};

export default usePreferences;
