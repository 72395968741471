import {
  FilePdfOutlined,
  FileExcelOutlined,
  FilePptOutlined,
  FileZipOutlined,
  FileImageOutlined,
  LinkOutlined,
  FileOutlined,
  MailOutlined,
  FileTextOutlined,
  AudioOutlined,
  VideoCameraOutlined
} from '@ant-design/icons';

const Files = {};

Files.humanFileSize = (bytes, si = true) => {
  let thresh;
  let units;
  if (si) {
    thresh = 1000;
    units = ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  } else {
    thresh = 1024;
    units = ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  }
  if (bytes < thresh) {
    return bytes + ' B';
  }
  let u = -1;
  while (bytes >= thresh) {
    bytes = bytes / thresh;
    u++;
  }
  return bytes.toFixed(1) + ' ' + units[u];
};

Files.fileIcon = record => {
    if (record.isUrl && !record.isSharepoint) {
      return LinkOutlined;
    }
    switch (record.fileType) {
      case 'Archive': return FileZipOutlined;
      case 'Audio': return AudioOutlined;
      case 'Document': return FilePdfOutlined;
      case 'Email': return MailOutlined;
      case 'Image': return FileImageOutlined;
      case 'Presentation': return FilePptOutlined;
      case 'Spreadsheet': return FileExcelOutlined;
      case 'Text': return FileTextOutlined;
      case 'Video': return VideoCameraOutlined;
    }
    return FileOutlined;
};

export default Files;
